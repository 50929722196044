<template>
  <div>
    <div class="flex el-upload-list el-upload-list--picture-card">
      <div v-for="(item, index) in fileList" :key="index" class="img_list el-upload-list__item is-ready">
        <img class="el-upload-list__item-thumbnail" :src="item.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(item)">
            <i class="el-icon"><zoom-in /></i>
          </span>

          <span v-if="!disabled" class="el-upload-list__item-delete" @click="imgDownload(item)">
            <i class="el-icon"><Download /></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="copyImage(item)">
            <i class="el-icon"><CopyDocument /></i>
          </span>
        </span>
      </div>
      <!-- 用于预览大图的 el-image 组件 -->
      <el-image
          id="imageRefId"
          :src="'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'"
          :preview-src-list="previewList"
          preview-teleported
      />
    </div>
  </div>
</template>

<script>
import { Delete, Download, CopyDocument, ZoomIn, Search } from "@element-plus/icons-vue";
import { download } from "@/utils/tool";

export default {
  name: "imageCom",
  components: {
    Delete,
    Download,
    CopyDocument,
    ZoomIn,
    Search
  },
  data() {
    return {
      fileList: [
        {
          url: "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg"
        }
      ],
      disabled: false,
      previewList: []
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.previewList = [file.url];
      this.$nextTick(() => {
        const image = document.getElementById("imageRefId");
        if (image) {
          image.click();
        }
      });
    },
    imgDownload(file) {
      download(file.url, {}, file.url);
    },
    async copyImage(file) {
      try {
        await navigator.clipboard.writeText(file.url);
        ElMessage.success("复制成功!");
      } catch (error) {
        ElMessage.error("复制失败!");
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.el-image {
  height: 0;
}
</style>
