import request from '@/utils/request'

// 网关
let gateway = '/api/uap2.0'

/* 获取用户信息 */
export function getUserInfo(data) {
    return request({
        url: gateway + '/app/user/getUserInfo',
        method: 'post',
        data: data
    })
}

/* 根据token获取用户名 */
export function getUserInfoByToken(data) {
    return request({
        url: gateway + '/app/user/getUserInfoByToken',
        method: 'post',
        data: data
    })
}

// 获取通知列表信息
export function getoNoticeList(data) {
    return request({
        url: gateway + '/personal/center/notice/list',
        method: 'post',
        data: data
    })
}

/* 修改密码 */
export function changePassword(data) {
    return request({
        url: gateway + '/personal/center/editPassword',
        method: 'post',
        data: data
    })
}

/* 认证手机号 */
export function authPhone(data) {
    return request({
        url: gateway + '/personal/center/authPhone',
        method: 'get',
        params: data
    })
}

//用户中心-更新用户信息
export function updatePersonUserInfo(data) {
    return request({
        url: gateway + '/personal/center/editUserInfo',
        method: 'post',
        data: data
    })
}


// 获取验证码
export function getCodeApi(data) {
    return request({
        url: gateway + '/getCode',
        method: 'post',
        data: data
    })
}

//注册
export function register(data) {
    return request({
        url: gateway + '/register',
        method: 'post',
        data: data
    })
}

// 验证用户名username
export function checkUsername(data) {
    return request({
        url: gateway + '/checkUsername',
        method: 'get',
        params: data
    })
}

//手机验证码验证

export function verifyMessageCode(data) {
    return request({
        url: gateway + '/verifyMessageCode',
        method: 'get',
        params: data
    })
}

// 获取验证码
export function getCode(data) {
    return request({
        url: gateway + '/getCode',
        method: 'post',
        data: data
    })
}

// 认证手机获取验证码
export function getAuthSmsCode(data) {
    return request({
        url: gateway + '/personal/center/getAuthSmsCode',
        method: 'get',
        params: data
    })
}

//修改密码（忘记密码）
export function changePasswordFort(data) {
    return request({
        url: gateway + '/changePassword',
        method: 'post',
        data: data
    })
}


// 注册第一步验证用户名
export function isUserExist(data) {
    return request({
        url: gateway + '/isUserExist',
        method: 'get',
        params: data
    })
}

// 注册第二步骤 获取验证码
export function registerOutPre(data) {
    return request({
        url: gateway + '/registerOutPre',
        method: 'get',
        params: data
    })
}

// 注册第三部注册
export function registerOut(data) {
    return request({
        url: gateway + '/registerOut',
        method: 'post',
        data: data
    })
}


// (2.0)提交注册资料-获取短信验证码
export function getRegisterSmsCode(data) {
    return request({
        url: gateway+ '/getRegisterSmsCode',
        method: 'get',
        params: data
    })
}

//(2.0)提交注册资料
export function submitRegisterInfo(data) {
    return request({
        url: gateway  + '/submitRegisterInfo',
        method: 'post',
        data: data
    })
}

//(2.0)补充注册资料
export function completeRegisterInfo(data) {
    return request({
        url: gateway  + '/completeRegisterInfo',
        method: 'post',
        data: data
    })
}

//(2.0)获取用户审批详情
export function getApprovalRecord(data) {
    return request({
        url: gateway  + '/getApprovalRecord',
        method: 'get',
        params: data
    })
}

//(2.0)获取企业营业分类列表
export function orgCategoryList(data) {
    return request({
        url: gateway  + '/orgCategoryList',
        method: 'get',
    })
}

// (2.0)获取手机登录验证码
export function getPhoneCode(data) {
    return request({
        url: gateway  + '/getPhoneCode',
        method: 'post',
        data: data
    })
}

// (2.0)获取手机登录验证码
export function logout(data) {
    return request({
        url: gateway  + '/logout',
        method: 'post',
        data: data
    })
}
