<template>
  <div class='register relative w-full h-full flex flex-center'>
    <div class='register_content'>
      <div class="title"> 用户入驻进度</div>
      <div class="flex register_box ">

        <div class="register_left">
          <div class="r_item flex " v-for="item in baseUser">
            <p class="p_h_1"> {{ item.name }}</p>
            <p class="p_h_2" v-if="item.key && item.key!=='orgBusinessLicense'">{{ list[item.key] }}</p>
            <p class="p_h_2 primary_text cursor-pointer" @click="handlePictureCardPreview"
               v-if="item.key==='orgBusinessLicense'">查看</p>

          </div>
        </div>
        <!-- 用于预览大图的 el-image 组件 -->
        <el-image id="imageRefId" :src="previewList[0]"
                  :preview-src-list="previewList" preview-teleported/>
        <div class="register_line"></div>
        <div class="register_right">
          <div class="flex  items-start justify-center">
            <div class="flex-shrink-0">
              <img v-if="list.approvalStatus=='1'  &&   list.approvalResult=='0'" src="@/assets/img/error.png" alt=""
                   style="width: 40px;">
              <img v-else src="@/assets/img/success.png" alt="" style="width: 40px;">

            </div>
            <div class="m_l_20">

              <p class=" m_b_20 p1">
                <span v-if="list.approvalStatus!=='1'"> 用户资料审核中请耐心等待。 </span>
                <span v-if="list.approvalStatus!=='1'"> 资料提交成功，1个工作日审核完成。 </span>
                <span class=" m_b_20 p1" v-if="list.approvalStatus!='1'">审核结果会以短信通知您。</span>
                <span class=" m_b_20 p1"
                      v-if="list.approvalStatus=='1'  &&   list.approvalResult=='1'">审核已通过。{{
                    list.approvalRemark
                  }}</span>
                <span class=" m_b_20 p1"
                      v-if="list.approvalStatus=='1'  &&   list.approvalResult=='0'">审核未通过。{{
                    list.approvalRemark
                  }}</span>

              </p>
              <div>
                <div class="cursor-pointer btn_reviewProgress" v-if="list.approvalResult=='1'"
                     v-ripple:color="'rgba(169,169,169,0.3)'" @click="jumpLogin">
                  前往登录
                </div>
                <div class="cursor-pointer btn_reviewProgress" v-else v-ripple:color="'rgba(169,169,169,0.3)'"
                     @click="jumpPage">
                  修改资料
                </div>
              </div>


            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  checkUsername,
  getCodeApi,
  isUserExist,
  register,
  registerOutPre,
  registerOut,
  getApprovalRecord
} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import {ElMessage} from "element-plus";
import getData from '@/assets/js/city1.json'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
import baseImg from '@/assets/img/APPC.png'
import OutlinedInput from "@/components/OutlinedInput.vue";
import {nextTick, onMounted, reactive, ref} from "vue";
import upload from "@/components/Upload/index.vue";
import {useRoute, useRouter} from "vue-router";
import ImageCom from "@/components/Image/index.vue";

const isShow = ref(false)
const previewList = ref([])

function handlePictureCardPreview() {
  previewList.value = [list.value.orgBusinessLicenseTemp];
  nextTick(() => {
    const image = document.getElementById("imageRefId");
    if (image) {
      image.click();
    }
  });
}

const router = useRouter()
let form = reactive({
  name: '',
  pwd: '',
  owner: 'pta',
  fileList: [],
})
const formInput = (row, type) => {
  console.log(row, type, form);
  if (row) {
    if (row.target) {
      form[type] = row.target.value
    }
  }
  // if(row.target.value){
  //   // form[type] = row.target.value
  // }
}
// 基本信息
const baseUser = ref([
  {name: "名字", key: "cnName"},
  {name: "手机号", key: "phone"},
  {name: "企业名称", key: "orgName"},
  {name: "企业统一社会信用代码", key: "orgCode"},
  {name: "营业执照", key: "orgBusinessLicense"},
])
const list = ref({})
/* 跳转*/
const jumpPage = () => {
  router.push({
    path: "/registerPage",
    query: {
      userId: route.query.userId ? route.query.userId : list.value.userId
    }
  })
}
/* 跳转登录 */
const jumpLogin = () => {
  router.push({
    path: "/login",
  })
}
const route = useRoute()
const getInfo = () => {
  getApprovalRecord({
    userId: route.query.userId || null
  }).then(res => {
    if (res.code === 200) {
      list.value = res.data
    }
  })
}
onMounted(() => {
  getInfo()
})
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/img/User/register.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .register_content {
    width: 1174px;
    min-height: 446px;
    background: #FFFFFF;
    box-shadow: 0px 3px 36px 4px #CDD3EB;
    border-radius: 0px 40px 0px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 20px;
    margin-bottom: 114px;

    .register_left {
      width: 50%;
      height: 100%;
      padding: 0 40px;

      .r_item {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        line-height: 35px;

        .p_h_1 {
          width: 60%;
          color: #666666;
          text-align: right;
          margin-right: 14px;
        }

        .p_h_2 {
          margin-left: 14px;
          width: 40%;
          color: #000;
          text-align: left;
        }

        .primary_text {
          color: var(--el-color-primary);
        }
      }
    }

    .register_line {
      width: 3px;
      height: 192px;
      background: #F5F5F5;
    }

    .register_right {
      width: calc(50% - 40px);
      height: 100%;
      padding: 0 40px 0 80px;

      .p1 {
        width: 80%;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }

      .width_50 {
        width: calc(50% - 10px);
      }
    }

    .btn_reviewProgress {
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid var(--el-color-primary);;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: var(--el-color-primary);
      cursor: pointer;
    }

    .title {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
      text-align: center;
      margin-bottom: 114px;

    }
  }

  .el-image {
    height: 0;
  }
}
</style>
