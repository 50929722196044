import request from '@/utils/request'

const api = {
    login: '/login',
    getMenuPrv: '/app/workbench/getAppMenuTree',
    getOrgInfoByUser: "/app/organization/getOrgInfoByUser",
    getAppInfo: "/app/appInfo/getAppInfo",
    exchangeForToken: '/exchangeForToken',
    getlogoinfo: '/api/business/logo/info'
}
// 网关
let gateway = '/api/uap2.0'


// 登录
export function login(param) {
    return request({
        url: gateway + api.login,
        method: 'post',
        headers: {
            Mark: 'applicationPlatform'
        },

        data: param
    })
}

// 根据路径获取登录模板信息
export function templateorgpath(param) {
    return request({
        url: gateway + '/V2.0/login/view/template/org/path',
        method: 'get',
        params: param
    })
}

export function getUserInfo() {
    return request({
        url: gateway + '/personal/center/getUserInfo',
        method: 'get',
    })
}

// 消息通知（数量）
export function noticeCount(data) {
    return request({
        url: gateway + '/personal/center/notice/count',
        method: 'post',
        data: data
    })
}

export function logout() {
    return request({
        url: gateway + '/logout',
        method: 'get',
    })
}

// 根据用户获取用户租户
export function getOrgInfoByUser() {
    return request({
        url: gateway + api.getOrgInfoByUser,
        method: 'get',

    })
}

// 组织列表
export function getOrgList() {
    return request({
        url: gateway + '/app/workbench/getOrgList',
        method: 'get',

    })
}

// 获取用户最近登录时间
export function getCurrentUserLoginTime() {
    return request({
        url: gateway + '/getCurrentUserLoginTime',
        method: 'get',
    })
}

// 根据用户租户获取用户菜单
export function getAppInfo(param) {
    return request({
        url: gateway + api.getAppInfo + '?orgCode=' + param,
        method: 'get',
    })
}

// 获取组织下的应用列表
export function getAppList(param) {
    return request({
        url: gateway + '/app/workbench/getAppList',
        method: 'get',
        params: param
    })
}

// 获取应用权限
export function getMenuPrv(appId) {
    return request({
        url: gateway + api.getMenuPrv,
        method: 'get',
        params: {appId: appId}
    })
}

// 获取应用快捷功能列表
export function getAppQuickFuncListApi(appId) {
    return request({
        url: gateway + '/app/workbench/getAppQuickFuncList',
        method: 'get',
        params: {appId: appId}
    })
}

// 获取新的token
export function getexchangeForToken(name) {
    return request({
        url: gateway + api.exchangeForToken,
        method: 'get',
        params: {
            orgCode: name
        },
    })
}

//获取新的logo
export function getlogoinfo() {
    return request({
        url: gateway + api.getlogoinfo,
        method: 'get',
    })
}

//关联注册用户和组织
export function addOrgUser(data) {
    return request({
        url: gateway + '/app/user/addOrgUser',
        method: 'post',
        data: data
    })
}

// 查询所有二级标志特点
export function getAllFeatures(data) {
    return request({
        url: gateway + '/app/user/getAllFeatures',
        method: 'get',
    })
}

// 根据企业类型查询所属的应用
export function selectAppByFeature(markCode) {
    return request({
        url: gateway + '/app/user/selectAppByFeature/' + markCode,
        method: 'get',
    })
}

// 关联组织和选中应用
export function addOrgApp(data) {
    return request({
        url: gateway + '/app/user/addOrgApp',
        method: 'post',
        data: data
    })
}

/* 添加访问记录 */
export function addUserVisitRecord(data) {
    return request({
        url: gateway + `/addUserVisitRecord`,
        method: 'post',
        data: data
    })
}

// 获取资讯分类列表
export function getInfoListmessage(data) {
    return request({
        url: gateway + `/app/workbench/getInfoList`,
        method: 'post',
        data: data
    })
}


// 资讯收藏和取消收藏
export function collectInfoApi(data) {
    return request({
        url: gateway + `/app/workbench/collectInfo`,
        method: 'post',
        headers: {
            conentType: 'application/x-www-form-urlencoded'
        },
        params: data
    })
}

//组织已订阅资讯分类列表
export function getOrgInfoCategoryListApi(data) {
    return request({
        url: gateway + `/app/workbench/getOrgInfoCategoryList`,
        method: 'get',
    })
}

// 资讯分类订阅和取消订阅
export function subscribeInfoCategoryApi(data) {
    return request({
        url: gateway + `/app/workbench/subscribeInfoCategory`,
        method: 'post',
        headers: {
            conentType: 'application/x-www-form-urlencoded'
        },
        params: data
    })
}

export function getApi(url, data) {
    return request({
        url: gateway + `${url}`,
        method: 'get',
        params: data
    })
}
