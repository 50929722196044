<template>
  <div class='register relative w-full h-full flex flex-center'>
    <div class='register_content'>
      <div class="title">{{ userId ? '补充用户资料' : '提交注册资料' }}</div>
      <div class="flex register_box ">

        <div class="register_left">
          <p class="title_name">用户信息</p>
          <OutlinedInput v-model:value="form.cnName" placeholder="请输入姓名" :inputHeight="42"
                         maxlength="10"
                         :typeInput="'text'"

          ></OutlinedInput>

          <OutlinedInput v-model:value="form.phone" placeholder="请输入手机号码" :inputHeight="42" v-if="!userId"
                         :typeInput="'text'" maxlength="11"

          ></OutlinedInput>

          <div class="flex items-center " v-if="!userId">
            <div class="" style="width:calc(100% - 130px )">
              <OutlinedInput v-model:value="form.smsCode" placeholder="验证码" :inputHeight="42"
                             :typeInput="'text'"

              ></OutlinedInput>
            </div>
            <div class="sendSms cursor-pointer" v-ripple="'rgba(255, 255, 255, 0.3)'" @click="getCodeFun">{{
                btnText
              }}
            </div>

          </div>
        </div>
        <div class="register_line"></div>
        <div class="register_right">
          <p class="title_name">企业信息</p>
          <div class="flex">
            <div class="width_50 m_r_10">
              <OutlinedInput v-model:value="form.orgName" placeholder="企业名称" :inputHeight="42"
                             :typeInput="'text'" maxlength="30"

              ></OutlinedInput>
            </div>
            <div class="width_50 m_l_10">
              <OutlinedInput v-model:value="form.orgCode" placeholder="企业社会信用代码" :inputHeight="42"
                             :typeInput="'text'" maxlength="18"

              ></OutlinedInput>
            </div>
          </div>
          <div class="flex">
            <div class="width_50 m_r_10">
              <p class="p1 m_b_20">企业营业分类：</p>
              <div class="classification flex flex-wrap">
                <div class="classification_item mr-2" :class="form.orgLabelIds.includes(item.id)?'active':''"
                     v-for="(item,index) in orgList" @click="chooseOrg(item)">
                  {{ item.name }}
                </div>

              </div>
            </div>
            <div class="width_50 m_l_10">
              <p class="p1 m_b_20">企业营业执照：</p>
              <upload :isImg="true" :multiple="true" :autoUpload="true" :limit="1" :format="['image/*']"
                      v-model:value="form.orgBusinessLicense" v-model:photoTemp="form.orgBusinessLicenseTemp"
                      action='/fire/api/uap2.0/businessLicenseUpload'
              >
                <div class="flex flex-col justify-center items-center">
                  <svg-icon icon-class="user-upload" class-name="file_icon"></svg-icon>
                  <p class="mt-1 upload_text">选择文件</p>
                </div>

              </upload>
            </div>
          </div>
        </div>
      </div>
      <div class="form_btn" @click="submit" v-ripple="'rgba(255, 255, 255, 0.3)'">提交</div>
    </div>
  </div>
</template>

<script setup>
import {
  completeRegisterInfo,
  getApprovalRecord,
  getRegisterSmsCode, orgCategoryList, submitRegisterInfo
} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import {ElMessage} from "element-plus";
import getData from '@/assets/js/city1.json'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
import baseImg from '@/assets/img/APPC.png'
import OutlinedInput from "@/components/OutlinedInput.vue";
import {onMounted, reactive, ref} from "vue";
import upload from "@/components/Upload/index.vue";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
let form = ref({
  cnName: '',
  phone: '',
  smsCode: '',
  orgName: '',
  orgBusinessLicense: '',
  orgCode: '',
  orgLabelIds: [],

})

const chooseOrg = (val) => {
  let id = val.id
// 检查 id 是否已存在
  const index = form.value.orgLabelIds.indexOf(id);

  if (index > -1) {
    // 如果 id 存在，则删除它
    form.value.orgLabelIds.splice(index, 1);
  } else {
    // 如果 id 不存在，则插入它
    form.value.orgLabelIds.push(id);
  }
}


function validateForm() {
  const errors = {};
  let isValid = true;
  let validationRules = {}
  // 定义校验规则
  if (userId.value) {
    validationRules = {
      cnName: {message: '请输入姓名', required: true},
      orgName: {message: '请输入组织名称', required: true},
      orgBusinessLicence: {message: '请输入营业执照', required: false},
      orgCode: {message: '请输入企业社会信用代码', required: false},
      orgLabelIds: {message: '请选择组织标签', required: false},
    };
  } else {
    validationRules = {
      cnName: {message: '请输入姓名', required: true},
      phone: {message: '请输入手机号', required: true},
      smsCode: {message: '请输入手机验证码', required: true},
      orgName: {message: '请输入组织名称', required: true},
      orgBusinessLicence: {message: '请输入营业执照', required: false},
      orgCode: {message: '请输入企业社会信用代码', required: false},
      orgLabelIds: {message: '请选择组织标签', required: false},
    };
  }


  // 遍历表单对象进行校验
  for (const field in form.value) {
    if (validationRules[field] && validationRules[field].required) {
      if (!form.value[field] || (Array.isArray(form.value[field]) && form.value[field].length === 0)) {
        errors[field] = validationRules[field].message;
        isValid = false;
      }
    }
  }
  // 如果有错误，返回错误对象；否则返回true
  return isValid ? true : errors;
}

const submit = () => {
  const validationResults = validateForm();
  if (validationResults === true) {
    let params = {
      "name": form.value.cnName,
      "orgBusinessLicence": form.value.orgBusinessLicense,
      "orgCode": form.value.orgCode,
      "orgLabelIds": form.value.orgLabelIds,
      "orgName": form.value.orgName,
      "phone": form.value.phone || route.query.phone,
      "userInfo": route.query.userId,
      "smsCode": form.value.smsCode
    }
    if (!userId.value) {
      submitRegisterInfo(params).then(res => {
        if (res.code === 200) {
          ElMessage.success(res.msg)
          sessionStorage.setItem("registerUserId", res.data)
          router.push({
            path: "/registerSuccess",
            query: {
              userId: res.data
            }
          })
        }
      })
    } else {
      completeRegisterInfo(params).then(res => {
        if (res.code === 200) {
          ElMessage.success(res.msg)
          sessionStorage.setItem("registerUserId", res.data)
          router.push({
            path: "/registerSuccess",
            query: {
              userId: res.data
            }
          })
        }
      })
    }

  } else {
    const firstError = Object.keys(validationResults)[0];
    const firstErrorMessage = validationResults[firstError];
    ElMessage.error(firstErrorMessage)
  }


}

const time = ref("")
const btnText = ref("获取验证码")
const btnDisabled = ref(false)
//获取手机验证码方法
const getCodeFun = () => {
  if (!btnDisabled.value) {
    // 校验手机号码
    if (!form.value.phone) {
      //号码校验不通过
      ElMessage.error("手机号不能为空")
      //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
    } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.value.phone)) {
      // 失去焦点后自动触发校验手机号规则
      ElMessage.error("手机号格式不正确")
    } else {
      let params = {
        "phone": form.value.phone,
      }
      getRegisterSmsCode(params).then(res => {
        if (res.code === 200) {
          time.value = 60;
          btnDisabled.value = true;
          //调用倒计时方法
          timer();
        } else {
          // 检测手机号是否已经存在过了
          ElMessage.error("该手机号已经注册过了")
          setTimeout(() => {
            router.replace("/login")
          }, 500)
        }
      }).catch(err => {
        btnText.value = "获取验证码";
        btnDisabled.value = false;
      })
    }
  }

}
const timer = () => {
  if (time.value > 0) {
    time.value--;
    btnText.value = time.value + "s后重新发送";
    setTimeout(timer, 1000);
  } else {
    time.value = 0;
    btnText.value = "获取验证码";
    btnDisabled.value = false;
  }
}
const orgList = ref([])
//  (2.0)获取企业营业分类列表
const getOrgList = () => {
  orgCategoryList().then(res => {
    orgList.value = res.data
  })
}
const getInfo = () => {
  getApprovalRecord({
    userId: route.query.userId
  }).then(res => {
    if (res.code === 200) {
      form.value = res.data
      form.value.orgLabelIds = []
      let orgLabelList =res.data.orgLabelList||[]
      form.value.orgLabelIds =orgLabelList.map(item=>item.key)

    }
  })
}
const route = useRoute()
const userId = ref("")
onMounted(() => {
  getOrgList();
  // 判断是不是修改资料
  userId.value = route.query.userId
  if (userId.value && userId.value !== 'userId') {
    getInfo()
  }
  if (route.query.phone) {
    form.value.phone = route.query.phone
  }
})
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/img/User/register.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .p1 {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }

  .register_content {
    width: 1174px;
    min-height: 446px;
    background: #FFFFFF;
    box-shadow: 0px 3px 36px 4px #CDD3EB;
    border-radius: 0px 40px 0px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 20px;

    .register_box {
      height: calc(100% - 55px);
    }

    .form_btn {
      width: 330px;
      height: 42px;
      background: var(--el-color-primary);
      color: #fff;
      text-align: center;
      line-height: 42px;
      margin: 10px auto;
      margin-top: 20px;

      border-radius: 2px 2px 2px 2px;
    }

    .title_name {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      line-height: 55px;
    }

    .register_left {
      width: 410px;
      height: 100%;
      padding: 0 40px;
    }

    .register_line {
      width: 3px;
      height: 343px;
      background: #F5F5F5;
    }

    .register_right {
      width: calc(100% - 450px);
      height: 100%;
      padding: 0 40px;

      .width_50 {
        width: calc(50% - 10px);
      }
    }

    .title {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
      line-height: 55px;
      text-align: center;
      margin-bottom: 20px;
    }


    .steps-box {
      width: 40%;
      margin: 0 auto;

      .stepIcon {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #919191;
        color: #919191;
      }

      .active {
        border: 1px solid #3381FF;
        color: #3381FF;
      }

      ::v-deep(.el-step__line) {
        height: 1px;
        position: absolute;
        border-color: inherit;
        background-color: transparent;
        border: 1px dashed var(--el-text-color-placeholder);
      }

      .el-step.is-simple:not(:last-of-type) .el-step__title {
        max-width: none;
      }
    }
  }

  .classification {
    .classification_item {
      background: #EDEFF7;
      border-radius: 4px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 30px;
      text-align: center;
      margin-bottom: 14px;
      padding: 2px 8px;
      cursor: pointer;
    }

    .classification_item.active {
      background: var(--el-color-primary);
      color: #fff
    }
  }


  .sendSms {
    width: 117px;
    height: 42px;
    line-height: 42px;
    margin-left: 11px;
    background: #F2F2F2;
    text-align: center;
    border-radius: 1px 1px 1px 1px;
    border: 1px solid #DDDDDD;
    font-family: Source Han Sans CN;
    font-weight: 300;
    font-size: 14px;
    color: var(--el-color-primary);
  }

  .file_icon {
    width: 30px;
    height: 23px;
    color: var(--el-color-primary);

  }

  ::v-deep(.el-upload--picture-card) {
    height: 90px;
    width: 90px;
  }

  .upload_text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}
</style>
