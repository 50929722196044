<template>
  <div>
    <div v-if="isImg">
      <div class="flex el-upload-list el-upload-list--picture-card">
        <div v-for="(item, index) in changeValue" :key="index" class="img_list el-upload-list__item is-ready">
          <img class="el-upload-list__item-thumbnail" :src="autoUpload ? item.tempUrl : item.url" alt="" />
          <span class="el-upload-list__item-actions ">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(item)">
                            <el-icon><zoom-in /></el-icon>
                        </span>

                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(item)">
                            <el-icon>
                                <Delete />
                            </el-icon>
                        </span>
                    </span>
        </div>
        <uploadCom v-bind="forwardedProps" :value="changeValue" @update:value="uploadComChange"
                   v-show="(isImg && limit > changeValue.length)">
          <slot></slot>
        </uploadCom>
      </div>
      <!-- 用于预览大图的 el-image 组件 -->
      <el-image :src="'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'"
                :preview-src-list="previewList" />
    </div>
    <div v-if="!isImg">
      <uploadCom v-bind="forwardedProps" :value="changeValue" @update:value="uploadComChange"
                 @update:remove="handleRemoveOther"></uploadCom>
    </div>
  </div>
</template>
<script>
import { defineComponent, PropType, ref, nextTick, computed, watch } from 'vue'
import { Delete, Download, Plus, ZoomIn, Search } from '@element-plus/icons-vue'
import uploadCom from './uploadCom.vue'
export default defineComponent({
  name: "upload",

  components: {
    Delete, Download, Plus, ZoomIn, Search, uploadCom
  },
  props: {
    // 图片还是文件·
    isImg: {
      type: Boolean,
      default: true
    },
    // 是否允许多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 允许上传文件的最大数量
    limit: {
      default: 20
    },
    // 单个文件最大M
    size: {
      default: 50
    },
    // 是否展示文件列表
    showFileList: {
      type: Boolean,
      default: false
    },
    // 格式
    format: {
      type: Array,
      default: () => []
    },
    // 是否自动上传文件
    autoUpload: {
      type: Boolean,
      default: true
    },
    // 是否显示提示语
    showTip: {
      type: Boolean,
      default: false
    },
    // url地址
    action: {
      type: String,
      default: '/fire/api/uap2.0/upload'
    },
    //双向绑定的值
    value: {
      type: [String, Array],
      default: []
    },
    // 备用值，当图片为一张时候使用
    photoTemp: {
      type: String,
      default: ''
    },
    thumbUrl: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const fileList = ref([])
    const changeValue = computed(() => {
      if (props.value == null) {
        fileList.value = []
      } else if (typeof props.value == 'string' && props.value != '') {
        fileList.value = [{ tempUrl: props.photoTemp, url: props.value, thumbUrl: props.thumbUrl }]
      } else {
        fileList.value = props.value
      }
      return fileList.value
    })
    // watch(() => props.value, (newVal, oldVal) => {
    //     changeValue()
    // }, { deep: true })
    // nextTick(() => {
    //     changeValue(true)
    // })
    const handleRemove = (file) => {
      if (props.isImg && props.limit == 1) {
        emit('update:value', null);
        emit('update:photoTemp', null);
        emit('update:thumbUrl', null);
        return
      } else {
        let fileNum = fileList.value.indexOf(file)
        let removeList = JSON.parse(JSON.stringify(fileList.value))
        removeList.splice(fileNum, 1)
        emit('update:value', removeList);
      }
    }
    // 放大以后的图片
    const previewList = ref([])
    const handlePictureCardPreview = (file) => {
      previewList.value = [file.tempUrl]
      // 模拟点击预览图片以触发 el-image 的预览功能
      nextTick(() => {
        const image = document.querySelector('.el-image__inner');
        if (image) {
          image.click();
        }
      });
    }

    const handleDownload = (file) => {
      console.log(file)
    }
    // 使用 computed 来创建一个新的对象，该对象包含所有 props
    const forwardedProps = computed(() => ({
      ...props
      // 如果需要，可以在这里添加额外的逻辑或属性
    }));
    const uploadComChange = (row) => {
      let rowList = []
      row.map((item, index) => {
        if (item.response) {
          rowList.push(item.response)
        } else {
          rowList.push(item)
        }
      })
      console.log(props.isImg,props.limit,rowList)

      if (props.isImg && props.limit == 1) {
        emit('update:value', rowList[0].url);
        emit('update:photoTemp', rowList[0].tempUrl);
        emit('update:thumbUrl', rowList[0].thumbUrl);
        return
      }
      emit('update:value', rowList)
    }
    const handleRemoveOther = (newValue) => {
      emit('update:value', newValue)
    }
    return {
      props,
      dialogImageUrl,
      dialogVisible,
      disabled,
      previewList,
      fileList,
      changeValue,
      handleRemove,
      handlePictureCardPreview,
      handleDownload,
      uploadComChange,
      forwardedProps,
      handleRemoveOther
    }
  }
})
</script>
<style lang='postcss' scoped>
.el-image {
  height: 0;
}

.el-upload--picture-card {
  display: none;
}

.img_list {
  border: 1px solid var(--el-border-color);
  border-radius: 6px;
  margin: 0 8px 8px 0;
  display: inline-flex;

  img {
    width: 148px;
    height: 148px;
  }
}

/* .el-upload-list--picture-card .el-upload-list__item-actions {
  opacity: 0;
} */
</style>
