// 引入封装好的SvgIcon组件
import SvgIcon from '@/components/svgIcon/SvgIcon.vue' // svg component

// 全局注册
export const registerSvgIcon = (app)=> {
    app.component('svg-icon', SvgIcon)
    const req = require.context('./svg', false, /\.svg$/)
    // eslint-disable-next-line
    const requireAll = (requireContext) =>
        requireContext.keys().map(requireContext)
    let result = requireAll(req)
}

