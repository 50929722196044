<template>
  <div
      class="bar m_t_20"
      v-if="password !== '' && password !== undefined"
      :style="{ background: barColor, width: width + '%' }">
    <!-- 展示文字 -->
    <div
        class="strength"
        :style="{ color:'#fff'}"
        v-if="password !== '' && password !== undefined">
      {{ strength }}
    </div>
  </div>

</template>
<script setup>
import { ref, watch, reactive } from 'vue';
import {ElMessage} from 'element-plus';
// 引入验证方法
import { checkPasswordRule, level } from './CheckPassword';

// 强度条颜色
const barColor = ref('');
// 强度条长度
const width = ref('');
// 强度条说明
const strength = ref('');
// 密码校验规则
const validatePassword = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'));
  } else {
    let name = '';
    const result = checkPasswordRule(value, name);
    if (result === '校验通过') {
      callback();
    } else {
      callback(new Error(result));
    }
  }
};
const ruleFormRef = ref();
// 注册表单校验规则
const rules = reactive({
  password: [{ validator: validatePassword, trigger: 'blur' }]
});

const props = defineProps({
  password: {
    type: String,
    default: '',
  },
})

// 监听注册页面的新密码变化状态，来改变密码强弱显示
watch(
    () => props.password,
    (newVal) => {
      if (newVal != '') {
        const res = level(newVal);
        if (res == '非常弱') {
          barColor.value = 'red';
          width.value = '20';
          strength.value = '非常弱';
        } else if (res == '弱') {
          barColor.value = '#ee795c';
          width.value = '40';
          strength.value = '弱';
        } else if (res == '一般') {
          barColor.value = 'orange';
          width.value = '60';
          strength.value = '一般';
        } else if (res == '强') {
          barColor.value = 'green';
          width.value = '80';
          strength.value = '强';
        } else if (res == '非常强') {
          barColor.value = '#1B8EF8';
          width.value = '100';
          strength.value = '非常强';
        }
      }
    }
);



// 提交校验方法
const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log('submit!');
      ElMessage({
        message: '注册成功！',
        type: 'success'
      });
    } else {
      console.log('error submit!');
      return false;
    }
  });
};
</script>

<style scoped lang="postcss">

</style>
