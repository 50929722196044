<template>
  <div>
    <template v-for="(item, index) in options">
      <template v-if="values.includes(item.value)">

        <el-tag
            v-if="elTagType"
            :disable-transitions="true"
            :key="item.value + ''"
            :index="index"
            :type="elTagType === 'primary' ? '' : elTagType"
        >{{ item.label }}</el-tag>
        <span
            v-else
            :key="item.value"
            :index="index"
        >{{ item.label }}</span>
      </template>
    </template>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  // 数据
  options: {
    type: Array,
    default: null,
  },
  // 当前的值
  value: [Number, String, Array],
  elTagType:{
    type: String,
    default: null,
  }
})
const values = computed(() => {
  if (props.value !== null && typeof props.value !== 'undefined') {
    return Array.isArray(props.value) ? props.value : [props.value];
  } else {
    return [];
  }
})
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
