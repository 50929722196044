<template>
  <div class="home_left_app_box ">
    <p class="home_left_title w-full flex items-center justify-between m_b_20">
      <span>应用列表（{{ searchTenantList.length }}个）</span>
      <el-input
          v-model="searchApp"
          style="width: 240px"
          placeholder="搜索应用"
          :suffix-icon="Search"
          @input="SearchAppHandle"
      />
    </p>
    <el-scrollbar>
      <div class="about-content flex  " v-if="searchTenantList.length">
        <div v-for="(item,index) in searchTenantList" class="box-card card  m_b_20 relative"
             :class="index%2===1?'m_l_10':'m_r_10'"
        >
          <div class="flex column p20">
            <div class="ab_top flex items-start justify-between">

              <div class="ab_left flex column">
                <!--          -direction-->
                <img v-if='item.photoTemp' :src='item.photoTemp' alt='' class="ab_left_img1">
                <img v-if='!item.photoTemp' :src='baseImg' alt='' class="ab_left_img1">
                <el-tag class="mt-2 column-tag">{{ item.type == 2 ? "app" : "web" }}</el-tag>
              </div>
              <div class="ab_right">
                <p class="flex justify-between">
                  <!--                  <span class='name  marquee' @click="chooseApp(item)">{{ item.name }}</span>-->
                  <div class="scroll-container name" @click="chooseApp(item)">
                    <div class=" text " :class="item.name&&item.name.length>10?'scroll-animation':''">{{
                        item.name
                      }}
                    </div>
                  </div>
                  <span class='time flex-shrink-0' :class="item.void==='1'?'jy':''">{{
                      isExpired(item.expireDate) ? '已过期' : displayText(item)
                    }}</span>
                </p>
                <p class='des'>{{
                    item.descr
                  }}
                </p>

              </div>

            </div>
            <div v-if="item.previewImages && item.previewImages.length > 2" class="ab_bottom flex items-center m_t_20">
              <div class="icon_btn_arrow  mr-1 flex-shrink-0 flex items-center justify-center"
                   v-ripple:color="'rgba(169,169,169,0.3)'"
                   @click="slidePrev(index)">
                <el-icon color="#999">
                  <ArrowLeftBold/>
                </el-icon>
              </div>
              <div class="swiper_box" v-if="item.previewImages&&item.previewImages.length">
                <swiper
                    class="swpier"
                    :modules="modules"
                    :slides-per-view="2"
                    :space-between="20"
                    direction="horizontal"
                    :ref="`swiper${index}`"
                    :noSwiping="true"
                    noSwipingSelector="img"
                    :loop="true"
                    :autoplay="{
                    delay: 2000,
                    disableOnInteraction: true,
                   stopOnLastSlide: false,
                 }"
                    @swiper="onSwiper($event,index)"
                >
                  <swiper-slide v-for=" (i,index) in item.previewImages" :key="i.id" class="flex justify-center"
                  >
                    <!--                    <imageCom :value="i" urlKey="path" urlTempKey="pathTemp" imageRefId="id2"></imageCom>-->
                    <img :src="i.thumbPathTemp" alt="" class="img-child"
                         @click.prevent="handlePictureCardPreview(item.previewImages,index)"
                    >
                  </swiper-slide>
                </swiper>
              </div>
              <div class=" icon_btn_arrow ml-1 flex-shrink-0 flex items-center justify-center"
                   v-ripple:color="'rgba(169,169,169,0.3)'"
                   @click="slideNext(index)">
                <el-icon color="#999">
                  <ArrowRightBold/>
                </el-icon>
              </div>
            </div>
            <div v-else-if="item.previewImages && item.previewImages.length == 2"
                 class="ab_bottom flex items-center m_t_20 two">
              <img :src="i.thumbPathTemp" class="img-child1" v-for="(i,index) in item.previewImages" :key="i.id"
                   @click.prevent="handlePictureCardPreview(item.previewImages,index)">
            </div>
            <div v-else-if="item.previewImages && item.previewImages.length == 1"
                 class="ab_bottom flex items-center m_t_20 two">
              <img :src="item.previewImages[0].thumbPathTemp" class="img-child1"
                   @click.prevent="handlePictureCardPreview(item.previewImages,0)">
              <img src="@/assets/images/zwtp.png" class="img-child1">
            </div>
            <div v-else class="ab_bottom flex items-center m_t_20 two">
              <img src="@/assets/images/zwtp.png" class="img-child1" v-for="(it,ind) in 2" :key="'img' + ind">
            </div>
            <div class="tell_btn flex items-center justify-center m_t_20" v-ripple:color="'rgba(169,169,169,0.3)'"
                 v-if="item.type != 2 &&!tell(item)" @click="chooseApp(item)">
              <svg-icon icon-class="go"></svg-icon>
              进入系统
            </div>
            <div class="tell_btn flex items-center justify-center m_t_20" v-ripple:color="'rgba(169,169,169,0.3)'"
                 v-if="tell(item)&&item.valid !== '1'" @click="tellPhone(item)">
              <svg-icon icon-class="contact"></svg-icon>
              联系续费
            </div>
            <div class="tell_btn flex items-center justify-center m_t_20" v-ripple:color="'rgba(169,169,169,0.3)'"
                 v-if="item.valid === '1'" @click="tellPhone(item)">
              <svg-icon icon-class="contact"></svg-icon>
              联系平台
            </div>
            <div class="tell_btn flex items-center justify-center m_t_20" v-ripple:color="'rgba(169,169,169,0.3)'"
                 v-if="item.type == 2 &&!tell(item)" @click="showAppInfo(item)">
              <svg-icon icon-class="go"></svg-icon>
              扫码查看
            </div>
            <div class="qcord flex items-center justify-center" @click="close" v-if="item.show">
              <transition name='fade-transform' mode='out-in'>
                <div class="flex_list ccc" id="qr-code">
                  <img :src='item.previewPathTemp||baseImg' alt='' class='mr-4 overlay img'>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <!-- 用于预览大图的 el-image 组件 -->
    <el-image id="imageRefId"
              :src="previewList[0]"
              :initial-index="previewListIndex"
              :preview-src-list="previewList" preview-teleported/>
  </div>

</template>

<script setup>
import {computed, defineEmits, defineProps, nextTick, onMounted, ref, h, watch} from "vue";
import baseImg from '@/assets/img/APPC.png'
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {Search, DArrowLeft, DArrowRight} from '@element-plus/icons-vue';
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import {Pagination, Autoplay} from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import {ArrowLeftBold, ArrowRightBold} from "@element-plus/icons-vue";
import {useStore} from "vuex";
import {updateTopic} from "@/utils/common";
import ImageCom from "@/components/Image/index.vue";
import {getApi} from "@/api";


const searchApp = ref("")
const searchTenantList = ref([])
const SearchAppHandle = (val) => {
  if (val) {
    searchTenantList.value = props.tenantList.filter(item => {
      return item.name.includes(val);
    })
  } else {
    searchTenantList.value = props.tenantList
  }
}
const refList = ref({})
const setRef = (el) => {
  // if (el && !refList.value.includes(el)) {
  //   refList.value.push(el.swiper); // 确保将 Swiper 实例推入 refList
  // }
}
const swiperRef = ref('');
const modules = ref([Pagination])
const slidePrev = (index) => {
  if (refList.value[`swiper${index}`]) {
    refList.value[`swiper${index}`].slidePrev();
  }
};
const slideNext = (index) => {
  if (refList.value[`swiper${index}`]) {
    refList.value[`swiper${index}`].slideNext();
  }
};
const previewList = ref([])
const previewListIndex = ref(0)

async function handlePictureCardPreview(row, index) {
  console.log(row, "---")
  previewListIndex.value = index
  if (row && row.length) {
    let loading = ElLoading.service({
      lock: true,
      text: 'Loading...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.4)'
    })
    let imgArr = []
    imgArr = row.map(item => ({
      thumbUrl: item.thumbPathTemp,
      originalUrl: item.originalUrl,
      path: item.path
    }));
    // 假设这是异步加载原图的函数
    const loadOriginalImage = async (item) => {
      // 模拟异步操作，比如从 API 获取原图 URL
      return new Promise((resolve) => {
        getApi("/getPreSignedUrl", {url: item.path}, false).then(res => {
          resolve(res.data);
        })
      });
    };
    for (let item of imgArr) {
      if (!item.originalUrl) {
        item.originalUrl = await loadOriginalImage(item);
      }
    }
    previewList.value = imgArr.map(item => item.originalUrl)
    nextTick(() => {
      loading.close()
      const image = document.getElementById("imageRefId");
      if (image) {
        image.click();
      }
    });
  }

}

function isExpired(dateString) {
  if (dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    return givenDate < currentDate;
  } else {
    return false
  }

}

const tell = (item) => {
  let time = item.expireDate
  let t = remainingDays(time)
  if (item.valid === "1") {
    return true
  }
  if (time === null) {
    return false;
  } else if (t <= 30) {
    return true;
  } else {
    return false;
  }
}
// 计算剩余天数
const remainingDays = (time) => {
  if (time === null) {
    return null;
  }
  const today = new Date();
  const expirationDate = new Date(time);
  const timeDiff = expirationDate - today;
  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return dayDiff;
}

// 根据剩余天数计算显示文本
const displayText = (item) => {
  let time = item.expireDate
  if (item.valid === "1") {
    return "应用已被禁用"
  } else {
    let t = remainingDays(time)
    if (time === null) {
      return '';
    } else if (t <= 30) {
      return `剩余 ${t} 天到期`;
    } else {
      return '';
    }
  }

}
const onSwiper = (swiper, index) => {
  if (swiper) {
    refList.value[`swiper${index}`] = swiper;
  }

};

const props = defineProps({
  // 子组件接收父组件传递过来的值
  tenantList: Array,
})
watch(() => props.tenantList, (newVal) => {
  // 在tenantList变化时执行搜索逻辑
  searchTenantList.value = newVal
});

const emit = defineEmits(['isApp', 'closeApp'])
const show = ref(true)
const close = () => {
  emit('closeApp')
}

const chooseApp = (item) => {
  // 判断是不是小程序
  if (item.type == 2) {
    item.show = true
    return;
  }
  if (item.type != 2) {   //1-Web 2-App小程序
    if (!isExpired(item.expireDate)) { // 判断app是不是已经过期了
      emit('isApp', item)
    } else {
      ElMessage.warning('应用已过期,请联系管理员处理')
    }
  }
}
// 扫码查看
const showAppInfo = (item) => {
  let arr = [item.previewImages[0]]
  handlePictureCardPreview(arr, 0)
}
const tellPhone = () => {
  getApi('/app/workbench/operateContact/query').then(res => {
    if (res.code === 200) {
      let data = res.data
      ElMessageBox({
        title: '提示',
        message: h('div', null, [
          h('p', null, data.description),
          h('p', `联系人：${data.contacts}`),
          h('p', `联系方式：${data.phone}`),
        ]),
      })
    }
  })


}
const getTime = (time) => {
  if (time) {
    return time
  } else {
    return '长期有效'
  }
}
// 主动暴露childMethod方法
defineExpose({chooseApp})
</script>
<style>
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0s;
}

.fade-transform-enter-from {
  opacity: 0;
  //transform: translateY(30deg);
}

.fade-transform-leave-to {
  opacity: 0;
  //transform: translateY(-30deg);
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>

.home_left_app_box {
  height: calc(100% - 55px);

  .home_left_title {
    span {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }
  }

  :deep().el-input__wrapper {
    border: 1px solid #dddddd !important;
    box-shadow: none;
    border-radius: 16px
  }

  :deep().el-input__inner::placeholder {
    color: #b3b3b3;
  }

}

.about-content {
  max-height: calc(100vh - 353px);
  flex-wrap: wrap;
  cursor: pointer;

  .column {
    flex-direction: column;

    .column-tag {
      margin-left: -7px;
    }
  }

  .ab_left {
    margin-right: 11px;

    .ab_left_img1 {
      width: 30px;
      height: 30px;
    }
  }

  .ab_right {
    width: calc(100% - 52px);

    .name {
      max-width: 80%;
    }
  }

  :deep().el-tag {
    background: #ffffff;
    color: #808080;
    font-size: 12px;
    border: 1px solid #b3b3b3;
  }
}

.f_20 {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.f_16 {
  font-size: 18px;
  text-align: center;
  color: #C1C1C1;
  margin-top: 10px;
}

.name {
  font-size: 14px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #020C33;
}

.time {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ff9800;
  line-height: 20px;
}

.des {
  height: 66px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #666666;
  margin-top: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.img {
  display: inline-block;
  height: 50px;
  width: 50px;
  overflow: hidden;
}

.card {
  width: calc(50% - 10px);
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #E6EBF1;

}

.icon_btn_arrow {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  //margin-left: 11px;
  //margin-right: 11px;
}

.icon_btn_arrow:hover {
  background: #EDEFF7;

}

.tell_btn {
  width: 100%;
  height: 40px;
  border: 1px solid #DDDDDD;
  margin: 0 auto;
  color: var(--el-color-primary);
  font-size: 14px;
  color: #0938F7;

  .svg-icon {
    height: 20px;
    width: 20px;
    margin-right: 14px;
  }
}

.tell_btn:hover {
  border: 1px solid var(--el-color-primary);
}

.qcord {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;

  .overlay {
    display: inline-block;
    height: 150px;
    width: 150px;
  }
}


</style>
<style lang="postcss">


.swiper_box {
  width: 80%;
  height: 80px;

  .swiper {
    height: 100%;
  }

  .swiper-slide {
    line-height: 40px;
    font-size: 12px;
    text-align: center;
    width: 144px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #E6E6E6;
  }
}

.img-child {
  max-height: 100%; /* 图片的最大宽度为容器的宽度 */
  width: auto; /* 高度自适应，保持宽高比 */
}

.img-child1 {
  width: 45%;
  height: 80px;
}

.two {
  justify-content: space-between;
  padding: 0 35px;
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}

.scroll-animation.text {
  display: inline-block;
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.scroll-animation .text {
  animation-play-state: paused;
}

.jy {
  color: #F44336;
  font-size: 12px;
}
</style>
