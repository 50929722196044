// src/directives/ripple.js

const Ripple = {
    color: 'rgba(0, 0, 0, 0.35)',
    zIndex: '9999',
    bind(el, binding) {
        const props = {
            event: 'mousedown',
            transition: 600
        };

        setProps(Object.keys(binding.modifiers), props);

        el._ripple = { props, clearRipple: () => {} };

        function rippler(event, el, bgColor) {
            const target = el;

            const targetBorder = parseInt(getComputedStyle(target).borderWidth.replace('px', ''), 10);

            const rect = target.getBoundingClientRect(),
                left = rect.left,
                top = rect.top,
                width = target.offsetWidth,
                height = target.offsetHeight,
                dx = event.clientX - left,
                dy = event.clientY - top,
                maxX = Math.max(dx, width - dx),
                maxY = Math.max(dy, height - dy),
                style = window.getComputedStyle(target),
                radius = Math.sqrt((maxX * maxX) + (maxY * maxY)),
                border = targetBorder > 0 ? targetBorder : 0;

            const ripple = document.createElement('div'),
                rippleContainer = document.createElement('div');

            rippleContainer.className = 'ripple-container';
            ripple.className = 'ripple';

            Object.assign(ripple.style, {
                marginTop: '0px',
                marginLeft: '0px',
                width: '1px',
                height: '1px',
                transition: `all ${props.transition}ms cubic-bezier(0.4, 0, 0.2, 1)`,
                borderRadius: '50%',
                pointerEvents: 'none',
                position: 'relative',
                zIndex: Ripple.zIndex || '9999',
                backgroundColor: bgColor
            });

            Object.assign(rippleContainer.style, {
                position: 'absolute',
                left: `${0 - border}px`,
                top: `${0 - border}px`,
                height: '0',
                width: '0',
                pointerEvents: 'none',
                overflow: 'hidden'
            });

            const storedTargetPosition = target.style.position.length > 0 ? target.style.position : getComputedStyle(target).position;

            if (storedTargetPosition !== 'relative') {
                target.style.position = 'relative';
            }

            rippleContainer.appendChild(ripple);
            target.appendChild(rippleContainer);

            ripple.style.marginLeft = `${dx}px`;
            ripple.style.marginTop = `${dy}px`;

            rippleContainer.style.width = `${width}px`;
            rippleContainer.style.height = `${height}px`;
            rippleContainer.style.borderTopLeftRadius = style.borderTopLeftRadius;
            rippleContainer.style.borderTopRightRadius = style.borderTopRightRadius;
            rippleContainer.style.borderBottomLeftRadius = style.borderBottomLeftRadius;
            rippleContainer.style.borderBottomRightRadius = style.borderBottomRightRadius;
            rippleContainer.style.direction = 'ltr';

            setTimeout(() => {
                ripple.style.width = `${radius * 2}px`;
                ripple.style.height = `${radius * 2}px`;
                ripple.style.marginLeft = `${dx - radius}px`;
                ripple.style.marginTop = `${dy - radius}px`;
            }, 0);

            function clearRipple() {
                setTimeout(() => {
                    ripple.style.backgroundColor = 'rgba(0, 0, 0, 0)';
                }, 250);

                setTimeout(() => {
                    if (rippleContainer.parentNode) {
                        rippleContainer.parentNode.removeChild(rippleContainer);
                    }
                }, 850);

                el.removeEventListener('mouseup', clearRipple, false);

                setTimeout(() => {
                    let clearPosition = true;
                    for (let i = 0; i < target.childNodes.length; i++) {
                        if ((target.childNodes[i]).className === 'ripple-container') {
                            clearPosition = false;
                        }
                    }

                    if (clearPosition) {
                        if (storedTargetPosition !== 'static') {
                            target.style.position = storedTargetPosition;
                        } else {
                            target.style.position = '';
                        }
                    }
                }, props.transition + 250);
            }

            if (event.type === 'mousedown') {
                el.addEventListener('mouseup', clearRipple, false);
                el._ripple.clearRipple = clearRipple;
            } else {
                clearRipple();
            }
        }

        el.addEventListener(props.event, (event) => {
            rippler(event, el, binding.value || Ripple.color || 'rgba(0, 0, 0, 0.35)');
        });

        el._ripple.clearRipple = () => {
            el.removeEventListener('mouseup', el._ripple.clearRipple);
        };
    },
    unbind(el) {
        if (el._ripple) {
            el.removeEventListener(el._ripple.props.event, el._ripple.clearRipple);
            delete el._ripple;
        }
    }
};

function setProps(modifiers, props) {
    modifiers.forEach(item => {
        if (isNaN(Number(item))) {
            props.event = item;
        } else {
            props.transition = Number(item);
        }
    });
}

const rippleDirective = {
    beforeMount(el, binding) {
        Ripple.bind(el, {
            value: binding.value,
            modifiers: binding.modifiers
        });
    },
    unmounted(el) {
        if (el._ripple) {
            el._ripple.clearRipple();
            delete el._ripple;
        }
    }
};

export default rippleDirective;
