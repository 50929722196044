<template>
  <div class="userInfo">
    <div class="right bg-white">
      <h5 class="m_b_20"> 基本信息</h5>
      <edit-info  @uploadInfo="uploadInfo"/>
    </div>
  </div>
</template>

<script>
  import { getUserInfo, updatePersonUserInfo, getUserInfoByToken } from "@/api/UserInfo"
  import { mapMutations, mapState } from "vuex";
  import editInfo from "@/views/User/editPersonalInfo/component/editInfo.vue";
  export default {
    name: "userInfo",
    data() {
      return {
        formInput:{},
        imageUrl: '',
        fileList: [],
        uploadFileUrl: '/fire/api/uap2.0/upload',
        headers: { Authorization: JSON.parse(localStorage.getItem('token')) },
        details: {},
        userInfo: [
          {
            keyIcon: 'user',
            key: 'cnName'
          },
          {
            keyIcon: 'phone',
            key: 'phone'
          },
          {
            keyIcon: 'email',
            key: 'email'
          },
          {
            keyIcon: 'id',
            key: 'idCardNo'
          },
          {
            keyIcon: 'sheng',
            key: 'area'
          }, {
            keyIcon: 'address',
            key: 'addr'
          }
        ],


      }
    },
    components: {
      editInfo,
    },
    methods: {
      ...mapMutations(['SET_HEADERIMG']),


      handleChange(uploadFile, uploadFiles) {
        this.fileList = [uploadFile]
      },
      beforeAvatarUpload() {
      },
      handleAvatarSuccess(res) {
        this.imageUrl = res.tempUrl
        let params = {
          "id": this.details.id,
          "photo": res.url,
          "addr": this.details.addr,
          "area": this.details.area,
          "cnName": this.details.cnName,
          "email": this.details.email,
          "idCardNo": this.details.idCardNo,
          "phone": this.details.phone,
        }
        updatePersonUserInfo(params).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改基本资料成功',
              duration: 1000,
            })
            this.SET_HEADERIMG(this.imageUrl)
            // 刷新个人中心
            this.$nextTick(() => {
              this.getInfo()
            })
          } else {
            this.$message({ type: 'error', message: '上传失败' })
          }
        }, error => {
        })
      },
      // getInfo() {
      //   // 根据 根据token获取用户名
      //   getUserInfoByToken().then(r => {
      //     if (r.code === 200) {
      //       getUserInfo({ username: r.data.userName }).then(res => {
      //         let arr = []
      //         let arrName = []
      //         this.details = res.data.sysUser
      //         this.details.areaArr =(res.data.sysUser.area)?(res.data.sysUser.area).split('-'):[]
      //         this.imageUrl = this.details.photoTemp ?  this.details.photoTemp : ''
      //       })
      //     }
      //   })
      // },
      uploadInfo() {

      },
    },
    mounted() {
     // this.getInfo()
    }
  }
</script>

<style scoped lang="less">
  .userInfo {
    margin:0 auto;
    position: relative;
    height: 100%;
    width: 100%;
    h5 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
      text-stroke: 1px rgba(0,0,0,0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0,0,0,0);
    }
    .right {
      width: 62%;
      height: 100%;
      padding: 20px;
      /*box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);*/
      border: 1px solid #e6ebf1;
      position: absolute;
      left:50%;
      margin-left: -32%;
    }
  }
</style>
