<template>
  <div class="notice">
    <div class="right bg-white">
      <h5 class="p20"> 通知列表</h5>

      <div class="list ">
        <el-scrollbar ref="scrollbar">
          <div v-if="noticeList.length > 0">
            <div class="flex justify-between list_item" v-for="item in noticeList">
              <div class="flex">
                <div class="relative">
                  <span class="tips" v-if="!item.whetherRead"></span>
                  <img src="../../assets/img/home.jpg" v-if="!item.dictClass" alt="" class="pic_icon bg m_r_20">
                  <div v-if="item.dictClass" v-html="item.dictClass" class="pic_icon m_r_20"></div>
                </div>

                <div class="no_center">
                  <p class="no_p_1">
                    {{ item.noticeType || '--' }}
                  </p>
                  <p class="no_p_2">{{ item.noticeTitle }}</p>
                </div>
              </div>

              <div class="no_right">
                <p class="no_p_3">{{ getTimeAgoText(item.releaseTime) }}</p>
                <p class="no_p_4 cursor-pointer" @click="jumpPage(item)">查看详情</p>
              </div>
            </div>
          </div>
          <el-empty v-else description="暂无数据"/>
        </el-scrollbar>

      </div>

      <div class="flex justify-end pagination">
        <el-pagination small background layout=' prev, pager, next,sizes, jumper' :total='paginations.total'
                       :page-size="paginations.pageSize" :current-page='paginations.pageNo'
                       :default-current-page='paginations.pageSize' :popper-append-to-body='false'
                       @size-change='handleSizeChange'
                       @current-change='handleCurrentChange'/>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserInfo, updatePersonUserInfo, getUserInfoByToken, getoNoticeList} from "@/api/UserInfo"
import {mapMutations, mapState} from "vuex";
import editInfo from "@/views/User/editPersonalInfo/component/editInfo.vue";
import {getTimeAgoText} from "@/utils/tool";
import dicTag from "@/components/DicTag/index.vue"
import {noticeCount} from "@/api";
import {DArrowLeft, DArrowRight, Search} from "@element-plus/icons-vue";

export default {
  name: "userInfo",
  data() {
    return {
      paginations: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },

      typeList: [
        {
          label: "审批通知",
          value: 1
        },
        {
          label: "任务通知",
          value: 2
        }
      ],
      noticeList: [],
      small: false,
      background: false,
      disabled: false
    }
  },
  components: {
    editInfo, dicTag,
  },
  computed: {
    ...mapState([
      "orgInfo", "appInfo",
    ]),
  },
  methods: {
    getTimeAgoText,
    ...mapMutations(['SET_HEADERIMG']),
    ...mapMutations(['SET_USERMANAGE', "SET_NOICENUMBER"]),
    // 更新通知消息
    updateNoice() {
      let params = {
        orgCode: this.orgInfo.orgCode,
        appId: this.appInfo.appId,
      }
      noticeCount(params).then(res => {
        if (res.code === 200) {
          let data = res.data || 0
          this.SET_NOICENUMBER(data);
        }
      })
    },
    // 页面跳转
    jumpPage(row) {
      let id = row.noticeId
      this.$router.push({
        name: 'noticeDetails'
        , query: {
          id: id
        }
      })
    },
    handleSizeChange(val) {
      this.paginations.pageNo = 1;
      this.paginations.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.paginations.pageNo = val;
      this.getInfo();
    },
    handleChange(uploadFile, uploadFiles) {
      this.fileList = [uploadFile]
    },
    beforeAvatarUpload() {
    },
    handleAvatarSuccess(res) {
      this.imageUrl = res.tempUrl
      let params = {
        "id": this.details.id,
        "photo": res.url,
        "addr": this.details.addr,
        "area": this.details.area,
        "cnName": this.details.cnName,
        "email": this.details.email,
        "idCardNo": this.details.idCardNo,
        "phone": this.details.phone,
      }
      updatePersonUserInfo(params).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '修改基本资料成功',
            duration: 1000,
          })
          this.SET_HEADERIMG(this.imageUrl)
          // 刷新个人中心
          this.$nextTick(() => {
            this.getInfo()
          })
        } else {
          this.$message({type: 'error', message: '上传失败'})
        }
      }, error => {
      })
    },
    getInfo() {
      let params = {
        "pageNo": this.paginations.pageNo,
        "pageSize": this.paginations.pageSize,
        orgCode: this.orgInfo.orgCode,
        appId: this.appInfo.appId,
      }
      getoNoticeList(params).then(r => {
        if (r.code === 200) {
          this.noticeList = r.data.records
          this.paginations.total = r.data.total
          this.$nextTick(() => {
            if (this.$refs.scrollbar) {
              this.$refs.scrollbar.setScrollTop(0)
            }
          })
        }
      })
    },
    uploadInfo() {

    },
  },
  mounted() {
    this.getInfo()
    this.updateNoice()
  }
}
</script>

<style scoped lang="less">
.notice {
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;

  .tips {
    position: absolute;
    top: 0;
    right: 5px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #FF0000;
    border-radius: 50%;
  }

  h5 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  }

  .right {
    width: 52%;
    height: 100%;
    box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);
    position: absolute;
    left: 50%;
    margin-left: -25%;

    .pic_icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .bg {
      background: var(--el-color-primary);
      padding: 5px;
    }

    .no_center {
      width: 70%;
    }

    .no_right {
      width: 100px;
      text-align: right;
    }

    .list {
      height: calc(100% - 120px);
      overflow: hidden;

      .list_item {
        margin-bottom: 30px;
        padding: 0 20px 0 20px;

      }
    }

    .no_p_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
      margin-bottom: 16px;
    }

    .no_p_2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #262626;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .no_p_3 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      text-stroke: 1px rgba(0, 0, 0, 0);
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: right;
      margin-bottom: 18px;
    }

    .no_p_4 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: var(--el-color-primary);
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: right;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }

    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}
</style>
