<!--
 * @Description:  工作台
 * @Author: yameier
 * @Date: 2024-05-20 15:43:45
 * @FilePath: \pta-ui3.0\example\main\src\views\
 -->
<template>
  <div class="home_box">
    <!--    <Nav></Nav>-->
    <div class="home_left h-full  m_r_20">
      <div class="home_left_info bg-white w-full flex items-center relative">
        <img class="avatar-img"
             :src="userInfo.photoTemp ? userInfo.photoTemp : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
             alt="">
        <div class="h_info">
          <p class="p1">{{ userInfo.cnName }}</p>
          <p class="p2">{{ currentTenant.name || '' }}</p>
          <p class="p3">{{ currentTenant.slogan }}</p>
        </div>
        <div class="ab_change_btn absolute" v-if="tenantList.length>1">
          <el-popover
              :width="300"
              ref="myPopover"
              trigger="click"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
          >
            <template #reference>
              <div class="h-full w-full  flex items-center justify-center" v-ripple:color="'rgba(169,169,169,0.3)'">
                <svg-icon icon-class="change"></svg-icon>
              </div>
            </template>
            <template #default>
              <div class="overflow-hidden" v-if="tenantList.length>8" style="height: 400px">
                <el-scrollbar>
                  <div :style="{height:'420px'}">
                    <div class="change_tenant flex items-center justify-between" v-for="item in tenantList"
                         @click.native="changeTenant(item)">
                      <div class="flex items-center flex-shrink-0">
                        <el-avatar :size="30"
                                   :src="item.photoTemp?item.photoTemp:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"/>
                        <p class="ml-2 truncate" style="width: 160px">{{ item.name }}</p>
                      </div>
                      <div class="change_btn  flex items-center justify-center " v-ripple:color="'rgba(169,169,169,0.3)'"
                           v-if="item.valid==='0'">
                        <svg-icon icon-class="change"></svg-icon>
                      </div>
                      <div class="flex-shrink-0" v-if="item.valid==='1'"> 已禁用</div>
                    </div>
                  </div>

                </el-scrollbar>
              </div>
              <div class="overflow-hidden" v-else>
                <div class="change_tenant flex items-center justify-between" v-for="item in tenantList"
                     @click.native="changeTenant(item)">
                  <div class="flex items-center flex-shrink-0">
                    <el-avatar :size="30"
                               :src="item.photoTemp?item.photoTemp:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"/>
                    <p class="ml-2 truncate" style="width: 160px">{{ item.name }}</p>
                  </div>

                  <div class="change_btn  flex items-center justify-center " v-ripple:color="'rgba(169,169,169,0.3)'"
                       v-if="item.valid==='0'">
                    <svg-icon icon-class="change"></svg-icon>
                  </div>
                  <div class="flex-shrink-0" v-if="item.valid==='1'"> 已禁用</div>
                </div>
              </div>

            </template>
          </el-popover>


        </div>
      </div>
      <div class="home_left_app m_t_20 w-full bg-white p20 relative">
        <div class="absolute h-full w-full home-a" @click="homeCLick" v-if="orgInfo.valid==='1'"></div>
        <com-about @isApp="isApp" @closeApp="closeApp" :tenantList="appList"
                   ref="comAbourRef"></com-about>
      </div>
    </div>
    <div class="home_right h-full bg-white relative" style="height: calc(100%)"
    >
      <div class="absolute h-full w-full home-b" @click="homeCLick" v-if="orgInfo.valid==='1'"></div>
      <informationList title="热门资讯" ref="informationListRef" :isAll="false"></informationList>
    </div>


    <!--    &lt;!&ndash;  切换组织信息  &ndash;&gt;-->
    <!--    <div class="flex items-center content-nav-top">-->
    <!--      <div class="c_fff f_14 m_r_20" v-if="!tenantList.length"> 企业未认证</div>-->
    <!--      <div v-if="tenantList.length">-->
    <!--        <el-dropdown @command='handleOptins($event)'>-->
    <!--              <span class="el-dropdown-link  mr-6 flex flex-center">-->
    <!--                <div class="c_fff">{{ chooseTenantName }}</div>-->
    <!--                <i class="xiala"></i>-->
    <!--              </span>-->
    <!--          <template #dropdown>-->
    <!--            <el-dropdown-menu>-->
    <!--              <el-dropdown-item v-for="(item,index) in tenantList" :key="index">{{-->
    <!--                  item.orgName-->
    <!--                }}-->
    <!--              </el-dropdown-item>-->
    <!--            </el-dropdown-menu>-->
    <!--          </template>-->
    <!--        </el-dropdown>-->
    <!--      </div>-->
    <!--    </div>-->


  </div>

</template>

<script>
import actions from "@/action";
import store from "@/store";
import {Search, DArrowLeft, DArrowRight} from '@element-plus/icons-vue';
import "@/utils/registerMicroAppsConfig"
import {getUserInfoByToken} from "@/api/UserInfo";
import {mapMutations, mapState, useStore} from "vuex";
import {
  addUserVisitRecord, getApi,
  getAppInfo,
  getAppList, getAppQuickFuncListApi,
  getexchangeForToken,
  getMenuPrv,
  getOrgInfoByUser,
  getOrgList,
  getUserInfo, noticeCount
} from "@/api";
import comAbout from '@/views/About.vue'
import informationList from '@/views/User/information.vue'
import Nav from "@/components/Nav.vue"
import {setLocal} from "@/utils/formExtend";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import {nextTick} from "vue";
import {updateTopic} from "@/utils/common";

export default {
  name: "qiankunMenu",
  components: {
    SvgIcon,
    comAbout,
    informationList,
    Nav
  },
  computed: {
    ...mapState([
      "userInfo",
      "orgInfo",
      "appInfo",
      'funList',
    ]),
    // 定义 SearchIcon 变量为 Search 图标组件
    SearchIcon() {
      return Search;
    }
  },
  data() {
    return {
      searchApp: '',
      senName: "",
      userAvatar: '',
      logoPhoto: "",
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      tenantList: [],
      currentTenant: [],
      appList: [],
      menuList: [],
      defaultMenuId: '',
      defaultMenuGroup: '',
      isHomeState: false

    }
  },
  mounted() {
    this.isSuccess()
    this.updateNoice()
    this.getInfo()

  },
  methods: {
    ...mapMutations(['SET_MENUID', 'SET_MENUGROUP', 'SET_FUNLIST', 'SET_HEADERIMG', "SET_ORGINFO", "SET_APPINFO", "SET_NOICENUMBER", "SET_MENULISTDIC", "setEditableTabs"]),
    ...mapMutations(['SET_USERMANAGE', "SET_NOICENUMBER",]),
    homeCLick(e) {
      if (this.orgInfo.valid === '1') {
        this.$message.warning("组织已被禁用，该功能不可用")
        e.stopPropagation()
      }
    },
    // 更新通知消息
    updateNoice() {
      let params = {
        orgCode: this.orgInfo.orgCode,
        appId: this.appInfo.appId,
      }
      noticeCount(params).then(res => {
        if (res.code === 200) {
          let data = res.data || 0
          this.SET_NOICENUMBER(data);
        }
      })
    },
    // 获取用户信息
    jumpTo() {
      this.$router.push({path: "/informationList"})
    },
    /**
     * 切换组织信息
     * @param val  组织信息item
     */
    changeTenant(val) {
      if (val.valid === '1') {
        this.$message.error("该组织已经被禁用，请联系管理员")
        return
      }
      this.currentTenant = val
      this.$refs.myPopover.hide(); // 关闭 Popover
      // 换取token
      this.changeToken(val)
      this.SET_ORGINFO(val)

    },
    // 换token
    changeToken(val) {
      getApi("/exchangeForToken", {orgCode: val.orgCode}).then(res => {
        let token = res.data.token
        setLocal('token', token, 1000 * 60 * 60)
        this.isSuccess()
        this.getNewToken(val)
        this.updateNoice()
      })
    },
    /**
     * 获取应用列表信息
     * @param val
     */
    async getNewToken(val) {
      // await getexchangeForToken(val.orgCode).then(item => {
      //   const token = item.data.token
      //   setLocal('token', token, 1000 * 60 * 60)
      // })
      await getAppList({tenantId: val.tenantId}).then(res => {
        let data = res.data.map(obj => ({...obj, show: false}));
        this.appList = data

      }).catch((err) => {
        this.appList = []
      })
    },
    getAppQuickFuncList(id) {
      getAppQuickFuncListApi(id).then((res => {
        this.shortcutKeyList = res.data || []
        this.SET_FUNLIST(res.data || [])
      }))
    },
    // 选择app
    isApp(row) {
      if (this.orgInfo.valid === '1') {
        this.$message.warning("组织已被禁用，该功能不可用")
        return;
      }
      if (row.valid === '1') {
        this.$message.warning("该应用已禁用，请联系管理员")
        return
      }
      localStorage.setItem('appid', row.appId)
      localStorage.setItem('appRow', JSON.stringify(row))
      this.SET_APPINFO(row)
      // 设置主题
      localStorage.setItem("topic", JSON.stringify(row.topic))
      // 获取用户选择应用的快捷功能
      this.getAppQuickFuncList(row.appId)
      getMenuPrv(row.appId).then(res => {
        if (!res.data) {
          this.$message.warning("暂时查询不到相关菜单")
          return
        }
        // updateTopic()
        this.updateNoice()
        this.SET_APPINFO(row)
        this.handleMenuListDic(res.data)
        let data = this.addLevelAndParentPath(res.data)
        this.menuList = this.addLevelAndParentPath(data)
        //计算路由白名单
        let whiteMenu = this.extractPaths(res.data)
        localStorage.setItem('whiteMenu', JSON.stringify(whiteMenu))
        localStorage.setItem('routerList', JSON.stringify(data))
        let first = this.findFirstPath(data)
        let to = first.path
        this.getActiveId(data, to)
        localStorage.setItem("defaultMenuId", this.defaultMenuId)
        /* 调用接口添加访问记录 */
        let params = {
          appId: row.appId,
          modelId: row.modelId,
          url: to,
          orgCode: this.currentTenant.orgCode
        }
        addUserVisitRecord(params).then(res => {
        })
        let editableTabs = []
        let obj = {
          title: first.name,
          name: first.path,
          content: first.name,
        }
        editableTabs.push(obj);
        this.setEditableTabs(editableTabs)
         this.$router.push(to)
        if (this.$router.currentRoute.value.fullPath === '/') {
          if (this.menuList[0].children !== null ) {
            localStorage.setItem('menuList', JSON.stringify(this.menuList[0].children[0]))
          } else {
            localStorage.setItem('menuList', JSON.stringify(this.menuList[0]))
          }
        } else {
          // if(this.menuList[0].children!==null){
          //     localStorage.setItem('menuList', JSON.stringify(this.menuList[0].children[0]))
          // }else{
          //     localStorage.setItem('menuList', JSON.stringify(this.menuList[0]))
          // }
        }
        this.$forceUpdate()

        // this.menuList.forEach((item)=>{
        //   let obj ={
        //     name: (item.path).substring(1),
        //         entry: process.env.VUE_APP_URL+':'+item.microAppPort,
        //       container: '#subapp-viewport',
        //       activeRule: item.path,
        //       props:{
        //       actions,
        //         routerBase: item.path, // 子应用的路由前缀(router的base)
        //         routerList: [], // 子应用的路由列表
        //   },
        //   }
        //   appList.push(obj)
        // })
        // 卸载全部子应用

        // 获取所有已加载的子应用程序实例
        //const apps = window.__POWERED_BY_QIANKUN__ || [];
        // // 遍历所有子应用程序实例，调用卸载方法
        // apps.forEach((app) => {
        //   app?.instance?.unmount();
        // });
        // console.log(appList)
        // appList.forEach(element => {
        //   loadMicroApp(element);
        // });
      })
    },
    extractPaths(menuItems, paths = []) {
      menuItems.forEach(item => {
        if (item.path) {
          paths.push(item.path);
        }
        if (item.children) {
          this.extractPaths(item.children, paths);
        }
      });
      return paths;
    },
    // 获取当前菜单的默认activeId
    getActiveId(data, to) {
      // 获取当前的菜单id
      let pathname = to
      this.defaultMenuId = findMenuIndex(data, pathname);

      function findMenuIndex(menuList, targetPath, parentIndex = '') {
        for (let i = 0; i < menuList.length; i++) {
          const menu = menuList[i];
          if (menu.path === targetPath) {
            return parentIndex === '' ? i.toString() : parentIndex + '-' + i;
          } else if (menu.children) {
            const index = findMenuIndex(menu.children, targetPath, parentIndex === '' ? i.toString() : parentIndex + '-' + i);
            if (index) {
              return index;
            }
          }
        }
        return null;
      }


    },
// 使用这个函数并传入树的根节点

    addLevelAndParentPath(items, parentPath = '') {
      return items.map((item) => {
        let newItem = {...item}; // 深拷贝
        newItem.parentPath = parentPath; // 设置上级路径
        if (parentPath === '') { // 如果上级路径是第一层，则不添加 /
          newItem.parentPath = item.name;
          // newItem.level = 0; // 计算层级
        } else {
          newItem.parentPath = `${parentPath}/${item.name}`;
        }
        newItem.level = newItem.parentPath.split('/').length - 1; // 计算层级
        if (item.children && item.children.length > 0) {
          newItem.children = this.addLevelAndParentPath(item.children, newItem.parentPath);
        }
        return newItem;
      });
    },
    // 处理menuList
    handleMenuListDic(data) {
      this.menuListDic = this.parseMenu(data)
      this.SET_MENULISTDIC(this.menuListDic)
    },
    parseMenu(data) {
      const result = {};

      function traverse(menuList, parentPath) {
        for (const item of menuList) {
          const path = item.path;
          if (path) {
            const [key, ...value] = path.split('/').filter(Boolean);
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push('/' + value.join('/'));
          }

          if (item.children) {
            traverse(item.children, path);
          }
        }
      }

      traverse(data, '');

      return result;
    },
    //
    findFirstPath(data) {
      // 遍历数据数组
      for (let i = 0; i < data.length; i++) {
        let result = this.findPath(data[i]);
        // 如果找到了路径，返回它
        if (result) {
          localStorage.setItem("menuList", JSON.stringify(data[i]))
          return result;
        }
      }
      // 如果没有找到任何路径，返回undefined
      return undefined;
    },
    findPath(node) {
      // 如果当前节点有效且包含路径，返回这个路径
      if (node.valid === "0" && node.path) {
        return node;
      }
      // 如果有子节点，递归检查子节点
      if (node.children && node.children.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          let result = this.findPath(node.children[i]);
          // 如果子节点中有路径，返回这个路径
          if (result) {
            return result;
          }
        }
      }
      // 如果当前节点无效或者没有子节点，返回undefined
      return undefined;
    },


    // 关闭app
    closeApp() {
      this.appList.forEach(item => {
        item.show = false
      })
    },
    async isSuccess(type = '') {
      this.getTreeList()
      this.$refs.informationListRef.getList()
      this.$refs.informationListRef.getOrgInfoCategoryList()
    },
    // 获取工作台组织列表
    getTreeList() {
      getOrgList().then(res => {
        if (res.code == 200) {
          this.tenantList = res.data || []
          if (this.tenantList.length) {
            if (this.orgInfo.orgCode) {
              this.currentTenant = this.tenantList.filter(item => item.orgCode === this.orgInfo.orgCode)[0]
            } else {
              this.currentTenant = this.tenantList[0]
            }
            this.SET_ORGINFO(this.currentTenant)
            this.getNewToken(this.currentTenant)
          }
        }
      })
    },


    /* 调用获取租户列表的list */

    // 获取应用列表信息
    getAppInfoApi() {
      getAppInfo(thixs.chooseTenant).then(res => {
        this.appList = res.data || []
        /*
             *  获取应用信息，如果应用不存在直接添加应用
             * */
        // if (!this.appList.length) {
        //   this.$router.push({path: "/additionInfo", query: {active: 3}})
        //   return
        // }
        /* 新增一个逻辑，如果列表只有一个直接跳进去  */
        // if (this.appList.length === 1) {
        //   this.$nextTick(() => {
        //     this.$refs.comAbourRef.chooseApp(this.appList[0])
        //   })
        // }
      })
    },
    getInfo() {
      getUserInfo().then(res => {
        if (res.code === 200) {
          store.commit('SET_USERMANAGE', res.data.sysUser);
        }
      })
    },
    // getInfo(){
    //   //localStorage.getItem('name')
    //   getUserInfo({username:localStorage.getItem('name')}).then(res=>{
    //     this.userAvatar = res.data.sysUser.photo ?  process.env.VUE_APP_URL_IMG  + res.data.sysUser.photo : ''
    //   })
    // },
    goHome(text) {

      this.$emit('goHome', text)
    },
    handleOptins(text) {
      this.$emit('handleOptins', text)
    },
    navigatorTo(row, id, groupId) {
      let to = row.path
      this.$router.push(to)
    },
  }
}
</script>
<style>
.el-image {
  height: 0;
}
</style>

<style lang="less" scoped>
.home_box {
  height: 100%;
  width: 100%;
  display: flex;

  .home_left {
    width: 50%;

    .home_left_info {
      padding: 20px 30px;
      border-radius: 8px;
      border: 1px solid #E6EBF1;

      .h_info {
        margin-left: 28px;
        display: inline-block;
        width: calc(100% - 160px);

        .p1 {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 20px;
          color: #000000;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .p2 {
          margin-top: 10px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .p3 {
          height: 21px;
          margin-top: 6px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          font-style: normal;
          text-transform: none;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          //width: 200px;        /* 设置宽度，根据实际需要调整 */
        }
      }
    }

    .home_left_app {
      height: calc(100% - 157px);
      border-radius: 8px;
      border: 1px solid #E6EBF1;
    }
  }

  .home_right {
    width: calc(50% - 20px);
    border-radius: 8px;
    border: 1px solid #E6EBF1;
    padding: 2px;

  }

  .ab_change_btn {
    top: 50%;
    margin-top: -7px;
    right: 20px;
    border-radius: 50%;
    width: 34px;
    height: 34px;

    & > div {
      border-radius: 50%;
    }

    .svg-icon {
      height: 14px;
      width: 14px;
    }

  }

  .ab_change_btn:hover {
    background: #EDEFF7;
  }
}

.change_tenant {
  padding: 10px 5px;
  cursor: pointer;
}

.change_tenant:hover {
  background-color: var(--el-menu-hover-bg-color);
}

.change_btn {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  .svg-icon{
   height: 50px;width:50px;
  }
}

.avatar-img {
  height: 96px;
  width: 96px;
  display: inline-block;
  border-radius: 50%;
}

.home-a {
  z-index: 2;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.home-b {
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.bg-gray-100 {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}

</style>
