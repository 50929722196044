<template>
  <div class="informationList">
    <informationList ref="informationListRef" :is-all="true"></informationList>
  </div>
</template>


<script setup>

import dicTag from "@/components/DicTag/index.vue";
import {defineProps, nextTick, onMounted, ref} from "vue";
import {Search, View} from "@element-plus/icons-vue";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import {getTimeAgoText} from "@/utils/tool";
import {collectInfoApi, getInfoListmessage, getOrgInfoCategoryListApi} from "@/api";
import {useRouter} from "vue-router";
import informationList from '@/views/User/information.vue'

const informationListRef = ref()

onMounted(() => {
  nextTick(() => {
    // informationListRef.value?.getList()
    informationListRef.value?.getOrgInfoCategoryList()
  })
})
// 收藏和取消收藏
const collectInfo = (id) => {
  let params = {
    infoId: id
  }
  collectInfoApi(params).then(res => {
    if (res.code === 200) {
      console.log(res)
      getList()
    }
  })
}
const tabsList = ref([])
//组织已订阅资讯分类列表
const getOrgInfoCategoryList = () => {
  getOrgInfoCategoryListApi().then(res => {
    if (res.code == 200) {
      tabsList.value = res.data
    }
  })
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
const router = useRouter()
// 查看更多
const seeMore = () => {
  router.push({path: "/informationList"})
}
const activeName = ref(null)
const handleClick = (type) => {
  activeName.value = type
  getList()
}
const typeList = ref([
  {
    label: "审批通知",
    value: 1
  },
  {
    label: "任务通知",
    value: 2
  }
])
const getList = () => {
  let params = {
    "infoCategoryId": activeName.value,
    "pageNo": 0,
    "pageSize": 0
  }
  getInfoListmessage(params).then(res => {
    if (res.code === 200) {
      list.value = res.data.records
    }
  })
}
defineExpose({getList, getOrgInfoCategoryList})
</script>
<style scoped lang="postcss">
.informationList {
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;

  .no_detials_title_box {
    padding: 0px 20px;
    border-bottom: 1px solid #E6E6E6;

    .p_h_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #020C33;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10px;
    }

    .p_h_2 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

    }

    .no_p_1 {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      text-stroke: 1px rgba(0, 0, 0, 0);
      text-align: left;
      font-style: normal;
      text-transform: none;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    }
  }

  ::v-deep(.el-input__wrapper) {
    border-radius: 30px;
  }

  ::v-deep(.el-switch__label) {
    font-size: 14px;
    color: #8C8C8C;
    font-weight: 400;

  }

  .tabs_item {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    padding: 2px 14px;
    cursor: pointer;
  }

  .tabs_item.active {
    color: var(--el-color-primary);
    border-bottom: 2px solid var(--el-color-primary);
  }

  h5 {
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-stroke: 1px rgba(0, 0, 0, 0);
    text-align: left;
    font-style: normal;
    text-transform: none;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  }

  .right1 {
    position: absolute;
    left: 50%;
    width: 52%;
    margin-left: -25%;
    box-shadow: 0px 2px 6px 1px rgba(3, 75, 143, 0.1608);

  }

  .right {
    height: 100%;

    .pic_icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background: #3F9DFD;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .no_center {
      width: 70%;
    }

    .no_right {
      width: 100px;
      text-align: right;
    }

    .list_bottom {
      height: calc(100% - 120px);
    }

    .h-list_bottom {
      height: calc(100% - 80px);
    }

    .list {
      overflow: hidden;

      .star_icon {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .list_item {

        padding: 0 20px;
        margin-bottom: 30px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #020C33;
        line-height: 30px;
        text-stroke: 1px rgba(0, 0, 0, 0);
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);

        .information_img {
          display: inline-block;
          width: 184px;
          height: 103px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #E6E6E6;
        }

        .list_title {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 14px;
          color: #020C33;
          text-stroke: 1px rgba(0, 0, 0, 0);
          text-align: left;
          font-style: normal;
          text-transform: none;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
        }

        .list_ite_left {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #8C8C8C;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .no_p_1 {
          display: inline-block;
          background: #EDEFF7;
          border-radius: 4px 4px 4px 4px;
          padding: 2px 10px;
          margin-right: 6px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #8C8C8C;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .information_des {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #262626;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .list_item:hover {
        .list_title {
          color: var(--el-color-primary);
        }
      }
    }

    .pagination {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .icon_btn_kjj {
      border-color: var(--el-button-border-color);
      font-size: var(--el-font-size-base);

      .svg-icon {
        height: 20px;
        width: 20px;
      }
    }

    .icon_btn_more {
      .svg-icon {
        height: 20px;
        width: 20px;
        color: var(--el-color-primary);
      }
    }
  }
}
</style>
