<template>
  <div class="layout-main-navbar flex justify-between items-center h-72  overflow-hidden relative z-10 ">
    <div class="flex" :style="{ width: computedWidth }">
      <div class="flex items-center px-4 cursor-pointer" @click="goHome" v-if="!isMenu">
        <div class="h-full flex items-center flex-shrink-0" style="margin-right:10px;">
          <svg-icon icon-class="logo" class="logo_icon" v-if="!orgInfo.photoTemp"></svg-icon>
          <div v-else>
            <img :src="orgInfo.photoTemp ? orgInfo.photoTemp : imgUrl" class="logo_icon_img" style="" alt=""/>
          </div>
        </div>
        <div class="layout-sidebar-logo relative m_r_20">
          <div class="scroll-container name">
            <span class="layout-header-title font-bold text ">{{
                orgInfo.name
              }}</span>
          </div>
          <!--          <p class="f_12 layout-header-des mt-1">{{ desTitle }}</p>-->
        </div>
      </div>
      <div class="flex items-center px-4 cursor-pointer" @click="goHome" v-if="isMenu">
        <div class="h-full flex items-center logo_icon_img" style="margin-right:10px;">
          <img v-if="appInfo.photoTemp" :src="appInfo.photoTemp" class="logo_icon_img" style="" alt=""/>
          <img v-else src="../assets/img/APPC.png" class="logo_icon_img" style=" " alt=""/>
        </div>
        <div class="layout-sidebar-logo relative m_r_20">
          <div class="scroll-container name" style="width: 250px">
            <span class="layout-header-title font-bold text "
                  :class="orgInfo.name.length > 10 ? 'scroll-animation' : ''">{{
                orgInfo.name
              }}</span>
          </div>
          <div class="scroll-container name" style="width: 140px">
            <p class="f_12 layout-header-des mt-1 ellipsis-text text"
               :class="orgInfo.name.length > 13 ? 'scroll-animation' : ''">{{ appInfo.name }}</p>
          </div>
        </div>
      </div>
      <div v-if="isMenu && mode === 'horizontal'" style="width: calc(100% - 360px);height: 67px;padding-top: 10px;">
        <div ref="myHorizonMenu" class="w-full">
          <sliderMenu :menuList="menuList" :defaultActive="defaultMenuId" :defaultMenuGroup="defaultMenuGroup"
                      :mode="mode" :menuNum="myHorizonMenuNum"></sliderMenu>
        </div>

      </div>
      <div class="relative" v-if="mode === 'vertical'" :style="{ width: computedTabWidth, paddingTop: '30px' }">
        <el-tabs v-model="editableTabsValue" type="card" class="nav-tabs">
          <el-tab-pane v-for="item in editableTabsList" :key="item.name" :label="item.title" :name="item.name">
            <template #label>
              <div class="flex justify-between items-center">
                <span @click.stop="changeTab(item.name)" style="margin-right: 9px;">{{ item.title }}</span>
                <el-icon @click.stop="removeTab(item.name)">
                  <Close/>
                </el-icon>
              </div>

            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="flex items-center px-4" id="navRight">
      <!-- 快捷键 -->
      <div class="flex" v-if="appInfo.appId">
        <div v-for="(item, index) in funList" :key="index" class="mr-2" v-show="funList.length > 3 ? index < 2 : true">
          <el-tooltip :content="item.menuName" placement="bottom" effect="light">
            <div class="icon_btn_kjj svg_hover" v-ripple:color="'rgba(169,169,169,0.3)'" @click="jumap(item)">
              <div class="svg-icon kjjColor" v-html="item.icon" v-if="item.icon"></div>
              <svg-icon icon-class="kjj " class-name="kjjColor" v-else></svg-icon>
            </div>
          </el-tooltip>
        </div>
        <div class="flex" v-if="funList.length > 3">
          <el-dropdown trigger="click" @command='handleCommand($event)'>
            <div class="icon_btn_kjj icon_btn_more more_bg" v-ripple:color="elTabsActiveColor">
              <svg-icon icon-class="more"></svg-icon>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <div v-for="(item, index) in funList" :key="index">
                  <el-dropdown-item :divided="index > 2" v-if="index > 1" :command='item'>
                    <div class="icon_btn_kjj_1 svg_hover icon-def" v-ripple:color="'rgba(169,169,169,0.3)'">
                      <div class="svg-icon kjjColor" v-html="item.icon" v-if="item.icon"></div>
                      <svg-icon icon-class="kjj" class-name="kjjColor" v-else></svg-icon>
                    </div>
                    {{ item.menuName }}

                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        </div>
      </div>

      <div class="danwei flex items-center text-[14px] " v-if="appInfo.banner">
        <div class="line m_r_20" style="margin-left: 12px;"></div>
        {{ appInfo.banner }}
        <div class="line m_l_20"></div>

      </div>
      <!--      <svg-icon icon-class="taiyang" class="taiyang_icon"></svg-icon>-->
      <!--      <div class="p_box">-->
      <!--        <p class="p1 text_left">20℃</p>-->
      <!--        <p class="p2 text_left">山西太原</p>-->
      <!--      </div>-->
      <div class="flex justify-between items-center ">
        <div class="p_box m_l_20 m_r_20 h-full mt-1">
          <p class="p1 ">{{ yearValueStr }}</p>
          <p class="p2">{{ lunarValueStr }} {{ dayStr }}</p>
        </div>
        <!--     时间     -->
        <div class="p_box m_r_20 h-full">
          <p class="p3">{{ timeValueStr }}</p>
          <p class="p4">{{ weekDayStr }}</p>
        </div>
      </div>

      <el-popover :width="200" ref="myPopover" trigger="click"
                  popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
        <template #reference>
          <div class="relative">
            <span class="tips" v-if="noiceNumber"></span>
            <el-avatar :size="45" shape="circle"
                       :src="userInfo.photoTemp ? userInfo.photoTemp : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
          </div>
        </template>
        <template #default>
          <div class="nav_avatar">
            <p class="name">{{ userInfo.cnName }}</p>
            <p class="des">{{ orgInfo.name }}</p>
            <div class="line"></div>
            <div>
              <p class="ability flex items-center cursor-pointer" @click="handleOptins('userInfo')">
                <svg-icon icon-class="user" class="ability_icon"></svg-icon>
                个人中心
              </p>
              <div class="ability flex items-center cursor-pointer" @click="handleOptins('notice')"
                   :class="orgInfo.valid === '1' ? 'backdrop-gray-bg' : ''">

                <svg-icon icon-class="tips" class="ability_icon"></svg-icon>
                消息通知
                <div class="tips_number" v-if="noiceNumber && noiceNumber !== '0'">{{ noiceNumber }}</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="logout cursor-pointer" @click="handleOptins('loginOut')">退出登录</div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
import actions from "@/action";
import "@/utils/registerMicroAppsConfig"
import {mapMutations, mapState} from "vuex";
import {logout} from "@/api";
import sliderMenu from '@/components/sliderMenu.vue'
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import {Close} from "@element-plus/icons-vue";
import {ElMessageBox} from "element-plus";
import {addEditableTabs} from "@/utils/tool";
import {Lunar} from 'lunar-javascript';

export default {

  name: "qiankunMenu",
  components: {
    Close,
    SvgIcon,
    sliderMenu
  },
  computed: {
    ...mapState([
      "userInfo",
      "orgInfo",
      "appInfo",
      'funList',
      "noiceNumber",
      'editableTabs'
    ]),
    computedWidth() {
      let bannerSize = this.appInfo.banner ? this.appInfo.banner.length * 18 : 0
      let timeSize = 380
      let funListSize = this.funList.length > 3 ? 150 : this.funList.length ? this.funList.length * 50 + 50 : 50
      return `calc(100vw - ${funListSize + bannerSize + timeSize}px)`;
    },
    computedTabWidth() {
      let bannerSize = this.appInfo.banner ? this.appInfo.banner.length * 18 : 0
      let timeSize = 380
      let funListSize = this.funList.length > 3 ? 150 : this.funList.length ? this.funList.length * 50 + 50 : 50
      return `calc(100vw - ${bannerSize + timeSize + funListSize + 360}px )`;
    },
    tabWidth() {
      let w = window.innerWidth
      let bannerSize = this.appInfo.banner ? this.appInfo.banner.length * 16 : 0
      let timeSize = 380
      let funListSize = this.funList.length > 3 ? 150 : this.funList.length ? this.funList.length * 50 + 50 : 50
      return `${w - bannerSize - timeSize - funListSize - 360}`;
    },
    editableTabsList() {
      let editableTabs = this.editableTabs
      // 计算范围内能放多少 editableTabs
      function calculateWidth(title) {
        return title.length * 18 + 40; // 每个字的宽度18px，加上padding 30px
      }

      let totalWidth = 0;
      let new_arr = [];
      // 从后往前遍历arr数组
      for (let i = editableTabs.length - 1; i >= 0; i--) {
        const item = editableTabs[i];
        const itemWidth = calculateWidth(item['title']);
        if (totalWidth + itemWidth <= Number(this.tabWidth)) {
          // 将元素插入到new_arr的开头
          new_arr.unshift(item);
          totalWidth += itemWidth;
        } else {
          break;
        }
      }

      return new_arr
      // this.editableTabsValue = location.pathname
    },
  },
  watch: {
    menuList(newVal, oldVal) {
      this.$nextTick(() => {
        this.getMenuNum()
      })
    }
  },
  props: {
    mode: {
      type: String,
      default: 'horizontal'
    },
    isMenu: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array,
      default: () => []
    },
    imgUrl: {
      type: String,
      default: ''
    },
    headerTitle: {
      type: String,
      default: '合邦电力科技有限公司'
    }, desTitle: {
      type: String,
      default: '电力交易辅助决策平台(试用)'
    },
    appName: {
      type: String,
      default: ''
    },
    senName: {
      type: String,
      default: ''
    },
    appNameEnName: {
      type: String,
      default: ''
    },
    defaultMenuId: {
      type: String || Number,
      default: ''
    }
  },
  data() {
    return {
      elTabsActiveColor: getComputedStyle(document.documentElement).getPropertyValue('--el-menu-icon-more-active-bg-colors'),
      weekDayStr: "",
      timeValueStr: "",
      yearValueStr: "",
      lunarValueStr: "",
      dayStr: "",
      widthLeft: 0,
      defaultMenuGroup: [],
      userAvatar: '',
      logoPhoto: "",
      chooseTenant: "", // 选择的租户 默认第一个
      chooseTenantName: "", // 选择的租户 默认第一个
      tenantList: [],
      appList: [],
      isHomeState: true,
      /* 标签页相关代码 */
      editableTabsValue: '',
      dateRightTopInterval: null,
      myHorizonMenuNum: 5
    }
  },
  mounted() {
    this.dateRightTopChange()
    this.$nextTick(() => {
      this.getMenuNum()
    })
  },
  unmounted() {
    clearInterval(this.dateRightTopInterval)
  },
  methods: {
    ...mapMutations(['SET_APPINFO', 'setEditableTabs']),
    dateRightTopChange() {
      this.dateRightTopInterval = setInterval(() => {
        const now = new Date()
        const year = now.getFullYear()
        const month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : '0' + (now.getMonth() + 1)
        const today = now.getDate() > 9 ? now.getDate() : '0' + now.getDate()
        const hour = now.getHours() > 9 ? now.getHours() : '0' + now.getHours();
        const minute = now.getMinutes() > 9 ? now.getMinutes() : '0' + now.getMinutes(); // 获取分钟
        const second = now.getSeconds() > 9 ? now.getSeconds() : '0' + now.getSeconds(); // 获取秒数

        this.weekDayStr = this.getWeekday(now.getDay())
        this.timeValueStr = hour + ':' + minute + ':' + second
        this.yearValueStr = year + '年' + month + '月' + today + '日'
        this.lunarValueStr = this.getLunarDate(new Date())
        let d = Lunar.fromYmd(year, month, today).getFestivals();
        this.dayStr = d[0]
      }, 1000)
    },
    getMenuNum() {
      if (this.$refs.myHorizonMenu) {
        this.myHorizonMenuWidth = this.$refs.myHorizonMenu?.offsetWidth
        let lastWidth = this.myHorizonMenuWidth - 60
        let myHorizonMenuNum = 0
        this.menuList.map((item, index) => {
          let nameWidth = item.name.length * 14 + 40
          lastWidth = lastWidth - nameWidth
          if (myHorizonMenuNum >= 6) {
            return
          }
          if (lastWidth > 0) {
            myHorizonMenuNum = index
          }
        })
        this.myHorizonMenuNum = myHorizonMenuNum
      }

    },
    getLunarDate(time) {
      // 转换为农历日期
      let lunarDate = Lunar.fromDate(new Date(time))
      // 输出结果
      return "农历" + lunarDate.getMonthInChinese() + "月" + lunarDate.getDayInChinese()
    },
    getWeekday(weekday) {
      let weekDayStr
      switch (weekday) {
        case 0:
          weekDayStr = '星期日'
          break;
        case 1:
          weekDayStr = '星期一'
          break;
        case 2:
          weekDayStr = '星期二'
          break;
        case 3:
          weekDayStr = '星期三'
          break;
        case 4:
          weekDayStr = '星期四'
          break;
        case 5:
          weekDayStr = '星期五'
          break;
        case 6:
          weekDayStr = '星期六'
          break;
      }
      return weekDayStr
    },
    // 快捷键下啦跳转
    handleCommand(item) {
      let obj = {
        name: item.menuName,
        path: item.path,
      }
      addEditableTabs(obj)
      if (item.path) {
        this.$router.push({
          path: item.path
        })
      } else {
        this.$message.warning("快捷键未配置跳转路由")
      }

    }
    ,
    // 跳转
    jumap(item) {
      let obj = {
        name: item.menuName,
        path: item.path,
      }
      addEditableTabs(obj)
      if (item.path) {
        this.$router.push(item.path)
      } else {
        this.$message.warning("快捷键未配置跳转路由")
      }
    }
    ,
    /* 更新tab*/
    initTab() {
      this.editableTabsValue = location.pathname
    }
    ,
    removeTab(targetName) {
      const tabs = this.editableTabs
      let activeName = this.editableTabsValue
      if (tabs && tabs.length > 1) {

        let newTabs = tabs.filter(item => item.name !== targetName);
        this.setEditableTabs(newTabs)
        this.initTab()
        if (activeName === targetName) {
          this.editableTabsValue = newTabs[newTabs.length - 1].name
          this.$router.push(this.editableTabsValue)
        }
      } else {
        this.$message.warning("至少保留一个页签")
      }
    }
    ,
    changeTab(name) {
      console.log("``````")
      this.$router.push(name)
    }
    ,
    // 回到主页
    goHome() {
      localStorage.removeItem('appid')
      localStorage.removeItem('imgUrl')
      localStorage.removeItem('appName')
      localStorage.removeItem('appNameEnName')
      localStorage.removeItem('routerList')
      localStorage.removeItem('menuList')
      localStorage.removeItem('editableTabs')
      localStorage.removeItem('topic')
      this.SET_APPINFO({})
      this.setEditableTabs([])
      this.$router.push('/app')
    }
    ,
    handleOptins(text) {
      this.$refs.myPopover.hide(); // 关闭 Popover
      if (text === 'loginOut') {
        ElMessageBox.confirm('确认退出登录吗？', '退出登录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          // 调用接口
          logout().then(res => {
            // 退出登录
            if (res.code === 200) {
              let activePath = localStorage.getItem('pathLogin')
              actions.setGlobalState({noToken: true, activePath: activePath});
            } else {
              this.$message.error(res.msg)
            }
          })
        })


      }
      if (text === '401') {
        // 退出登录
        this.resetData()
        actions.setGlobalState({noToken: true, activePath: '/'});
        this.getRouters()
      }
      if (text === 'userInfo') {
        // 添加页签
        addEditableTabs(
            {
              name:"个人中心",
              path:'/userInfo'
            }
        )
        // 记录跳转个人中心前页面的path
        let path = location.pathname + location.search
        localStorage.setItem('userPath', path)
        // 用户中心
        this.$router.push('/userInfo')
      }
      if (text === 'notice') {
        if (this.orgInfo.valid === '1') {
          this.$message.warning("组织已被禁用，该功能不可用")
          return
        }
        // 添加页签
        addEditableTabs(
            {
              name:"消息通知",
              path:'/notice'
            }
        )
        // 记录跳转个人中心前页面的path
        let path = location.pathname + location.search
        localStorage.setItem('userPath', path)
        // 用户中心
        this.$router.push('/notice')
      }
    }
  }

}
</script>
<style lang="less">
.logo_icon {
  height: 3em;
  width: 3em;
  color: #0938F7;
}

.logo_icon_img {
  height: 3em;
  width: 3em;
}

.more_other_kjj {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.nav-tabs.el-tabs--card {
  .el-tabs__header .el-tabs__item.is-active {
    background: var(--el-tabs-active-color) !important;
    color: var(--el-tabs-active-text-color) !important;
    border: 1px solid transparent;

    .el-icon {
      color: var(--el-tabs-del-avtive-color) !important;
    }
  }
}
</style>
<style lang="less" scoped>
@import "../assets/css/common.less";


.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}

.scroll-animation.text {
  display: inline-block;
  animation: scroll-left 10s linear infinite;
}

.layout-header-title {
  color: var(--nav-h1-color);
  font-size: 20px;
}

.layout-header-des {
  color: var(--nav-h2-color);
  font-size: 12px;
}


.layout-main-navbar {
  width: 100vw;
  background: var(--nav-bg-color);
  border-bottom: 1px solid var(--nav-bottom-right);
}

.danwei {
  font-weight: 400;
  font-size: 14px;
  color: var(--nav-text-color);
  text-align: left;
  font-style: normal;
  text-transform: none;


}

.line {
  width: 1px;
  height: 30px;
  background: var(--nav-line-bg);
}

.shortcut {
  display: inline-block;
  height: 20px;
  margin-right: 20px;
}

.p_box {

  .p1 {
    color: var(--nav-text-color);
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
  }

  .p2 {
    color: var(--nav-text-color);
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
  }

  .p3 {
    width: 90px;
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--nav-text-time-color);
  }

  .p4 {
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
    font-weight: 400;
    font-size: 12px;
    color: var(--nav-text-color);
  }


}

.tips {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #FF0000;
  border-radius: 50%;
}

.icon_btn_more {
  .svg-icon {
    height: 20px;
    width: 20px;
    color: var(--el-menu-icon-more-colors);
  }
}

.nav-tabs {

  ::v-deep(.el-tabs__item) {
    margin-right: 10px;
    border-radius: 12px 12px 0 0;
    border: 1px solid var(--el-tabs-border-color) !important;
    border-bottom: 0 !important;
    padding: 0 12px !important;
    height: 36px !important;
    background: var(--el-tabs-bg-color) !important;
    color: var(--el-tabs-text-color) !important;

    .el-icon {
      color: var(--el-tabs-del-color) !important;
    }
  }

  ::v-deep(.el-tabs__item:hover) {
    background: var(--el-tabs-hover-color) !important;
    color: var(--el-tabs-hover-text-color) !important;

    .el-icon {
      color: var(--el-tabs-del-hover-color) !important;
    }
  }


  ::v-deep(.el-tabs__header) {
    margin-bottom: 0;
    border-bottom: none;
    height: 36px !important;

    .el-tabs__nav {
      border: none !important;
    }
  }
}

.icon_btn_kjj {
  border-color: var(--el-button-border-color);
  padding: 15px;
  font-size: var(--el-font-size-base);

  .svg-icon {
    height: 20px;
    width: 20px;
  }
}

.icon_btn_kjj_1 {
  border-color: var(--el-button-border-color);
  font-size: var(--el-font-size-base);
  padding: 5px 10px 5px 5px;

  .svg-icon {
    height: 20px;
    width: 20px;
  }
}

.icon-def {
  color: var(--el-text-color-regular);

  .kjjColor {
    color: var(--el-text-color-regular);
  }
}


.taiyang_icon {
  height: 30px;
  width: 30px;
  margin: 0 20px;
}

.nav_avatar {
  .name {
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-bottom: 3px;
  }

  .des {
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    text-align: left;
    text-transform: none;
    margin-top: 10px;
  }

  .ability {
    margin-bottom: 5px;
    padding: 5px 10px;

    .ability_icon {
      max-width: 16px;
      max-height: 16px;
      margin-right: 15px;
    }

    .tips_number {
      width: 20px;
      height: 20px;
      line-height: 20px;
      background: #FF0000;
      font-family: Microsoft YaHei, Microsoft YaHei, serif;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      font-style: normal;
      text-transform: none;
      border-radius: 50%;
      margin-left: 30px;

    }
  }

  .ability:hover {
    background-color: var(--el-menu-hover-bg-color);
  }

  .line {
    height: 2px;
    width: 100%;
    background: #F0F0F0;
    opacity: 0.25;
    margin-top: 16px;
    margin-bottom: 17px;
  }

  .logout {
    text-align: center;
    font-family: Microsoft YaHei, Microsoft YaHei, serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--el-color-primary);

  }

}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}

.scroll-animation.text {
  display: inline-block;
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.scroll-animation .text {
  animation-play-state: paused;
}

.kjjColor {
  color: var(--el-menu-icon-colors)
}

.svg_hover:hover {
  background: var(--el-menu-icon-hover-colors);
  border-radius: 4px;
}

.more_bg:hover {
  background: var(--el-menu-icon-more-bg-colors);
}

.backdrop-gray-bg {
  color: #888;
  /* 暗淡的颜色表示不可用 */
  text-decoration: line-through;
  /* 斜线表示被禁用或无效 */
}

.icon_btn_more:hover {
  background: var(--el-menu-icon-more-hover-bg-colors);
  border-radius: 4px;

  .svg-icon {
    //color:var(--el-menu-icon-more-hover-bg-colors)!important;
  }
}
</style>
