<template>
  <div class="custom-input-container">
    <el-input
        v-model="inputValue"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="emitInput"
        :type="typeInput"
        show-word-limit
        :disabled="disabled"
        :placeholder="placeholder"
        :showPassword="showPassword"
        :style="{height: inputHeight+'px'}"
        :class="{'boxsha': isBorder}"
        :maxlength="maxlength"
    />
    <div :class="{
          'input-focused': focused || inputValue,
          'input-tip': !focused && !inputValue,
          'noColorPrimary': inputValue && !focused
        }">{{ placeholder }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: String,
    typeInput: String,
    maxlength: String,
    showPassword: Boolean,
    disabled: Boolean,
    inputHeight: Number,
    isBorder: Boolean
  },
  data() {
    return {
      inputValue: this.value,
      focused: false
    };
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  watch: {
    value: {
      handler(newVal) {
        this.inputValue = newVal;
      },
      immediate: true
    },
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
      this.inputValue = value;
    },
    // emitInput(value) {
    //   this.$emit('value', value);
    // },
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      this.focused = false;
      this.$emit('update:value', this.inputValue);
    }
  }
}
</script>

<style scoped lang="less">
.custom-input-container {
  position: relative;
  margin-bottom: 24px; /* 调整与下方元素的间距 */
  padding-top: 20px;
}

.input-focused {
  transform: translate(14px, -6px) scale(1);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  position: absolute;
  top: 18px; /* 提示信息与输入框的距离 */
  left: -2px; /* 提示信息与输入框左边的距离 */
  padding: 2px 5px;
  background-color: #fff; /* 背景颜色 */
  border-radius: 2px;
  font-size: 12px;
  line-height: 9px;
  color: var(--el-color-primary);
  z-index: 1; /* 确保在输入框上方显示 */
  opacity: 1; /* 默认显示 */
}


.input-tip {
  position: absolute;
  top: 50%; /* 提示信息与输入框的距离 */
  left: 1px; /* 提示信息与输入框左边的距离 */
  opacity: 0; /* 默认显示 */
  transform: translate(14px, 6px) scale(1);
  z-index: -1; /* 确保在输入框上方显示 */
}

.noColorPrimary {
  color: #9f9f9f;
}

</style>
<style>
/* .el-input__wrapper:hover{
  box-shadow: none ;
} */
.boxsha .el-input__wrapper {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
</style>
