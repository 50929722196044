<template>
  <div class='register relative w-full h-full flex flex-center'>
    <div class='register_result_content'>
      <div class="title">提交注册资料</div>
      <div class="flex flex-col items-center justify-center">
        <img src="@/assets/img/success.png" alt=""  style="width: 40px;">
      <p class="m_t_20  m_b_20 p1">资料提交成功，1个工作日审核完成，审核结果会以短信通知您。</p>
       <p class="p2 cursor-pointer" @click="jumpPage"> 查看审核进度</p>
      </div>

    </div>
  </div>
</template>

<script setup>
import {checkUsername, getCodeApi, isUserExist, register, registerOutPre, registerOut} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import {ElMessage} from "element-plus";
import getData from '@/assets/js/city1.json'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
import baseImg from '@/assets/img/APPC.png'
import OutlinedInput from "@/components/OutlinedInput.vue";
import {reactive} from "vue";
import upload from "@/components/Upload/index.vue";
import SvgIcon from "@/components/svgIcon/SvgIcon.vue";
import {useRoute, useRouter} from "vue-router";

let form = reactive({
  name: '',
  pwd: '',
  owner: 'pta',
  fileList:[],
})
const formInput = (row, type) => {
  console.log(row, type, form);
  if (row) {
    if (row.target) {
      form[type] = row.target.value
    }
  }
  // if(row.target.value){
  //   // form[type] = row.target.value
  // }
}
const  router = useRouter()
const  route = useRoute()
const jumpPage = () => {
  router.push({
    path:"/reviewProgress",
    query:{
      userId:route.query.userId
    }
  })
}
// export default {
//   components: {OutlinedInput},
//   data() {
//     return {
//       cityData:getData,
//       baseImg,
//       formInline: {},
//       checkList: [],
//       tenantList: [
//         {
//           cnName: 'hahhahahh ',
//           expireState: "1231231232",
//           descr: '使用的额题啊见是你是的粉红色短裤粉红色会计收到回复看手机话费卡是几点话费快捷方式肯定发生的纠纷哈地方开始的减肥后开始绝代风华就快点放寒假史蒂芬霍金是倒海翻江法就哈萨克',
//           pathTemp: ''
//         }, {
//           cnName: '12312',
//           expireState: "1231231232",
//           descr: '12312',
//           pathTemp: ''
//         }, {
//           cnName: '12312',
//           expireState: "1231231232",
//           descr: '12312',
//           pathTemp: ''
//         }
//       ],
//       sendShort: '',
//       totalTime: '10',
//       canClick: true, // 控制是否可以点击
//       dialogVisible: false,
//       active: 0,
//       btnText: "获取验证码",
//       time: 60,
//       ruleForm: {
//         username: '',
//         password: '',
//         phone: '',
//         sms: ''
//       },
//       rules: {
//         username: [
//           {pattern: /^[a-zA-Z0-9_]+$/, message: '请输入由英文字母、下划线、数字构成的用户名', trigger: ['change', 'blur']}
//         ],
//         phone: [
//           {required: true, message: '请输入手机号', trigger: 'blur'},
//           {
//             pattern: /^1[3-9]\d{9}$/,
//             message: '手机号格式不正确',
//             trigger: 'change'
//           }
//         ],
//         password: [
//           {required: true, message: '请输入密码', trigger: 'blur'},
//           {
//             pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[A-Za-z\d!@#$%^&*()_+.]{8,24}$/,
//             message: '密码包含字母数字特殊字符长度8到24位',
//             trigger: ['change', 'blur']
//           },
//         ],
//
//       }
//     }
//   },
//   methods: {
//     chooseApp(index) {
//       if (this.checkList.includes(index)) {
//         this.checkList = this.checkList.filter(item => {
//           return item !== index
//         })
//       } else {
//         this.checkList.push(index)
//       }
//     },
//     next() {
//
//       // return;
//       if (this.active === 0) {
//         if (!this.ruleForm.username) {
//           ElMessage.error("用户名不能为空")
//           return
//         }
//         if (!this.ruleForm.password) {
//           ElMessage.error("密码不能为空")
//           return
//         }
//         if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+.])[A-Za-z\d!@#$%^&*()_+.]{8,24}$/.test(this.ruleForm.password)) {
//           ElMessage.error("密码格式不正确")
//           return
//         }
//         // 验证用户名是否重复
//         isUserExist({username: encrypt.encrypt(this.ruleForm.username)}).then(res => {
//           if (res.code === 200) {
//             this.active += 1
//           } else {
//             ElMessage.error("请重新输入")
//           }
//         })
//       } else {
//         if (!this.ruleForm.code) {
//           ElMessage.error("验证码不能为空")
//           return
//         }
//         let params = {
//           "code": this.ruleForm.code,
//           "password": encrypt.encrypt(this.ruleForm.password),
//           "phone": this.ruleForm.phone,
//           "username": encrypt.encrypt(this.ruleForm.username),
//           registerSource:"平台注册",
//         }
//         registerOut(params).then(res => {
//           sessionStorage.setItem('loginUserName',this.ruleForm.username)
//           ElMessage.success("注册成功")
//           /* 调准到补充资料的页面中*/
//           this.$router.push("/additionInfo")
//           this.$emit("additionalInfo")
//         })
//       }
//     },
//     // 返回
//     goback() {
//       this.active = 0
//       // this.ruleForm
//     },
//     goLogin() {
//       this.$router.push('/login')
//     },
//     closeMask() {
//       this.dialogVisible = false
//       // window.clearInterval(this.cloak);
//       // this.totalTime='10';
//     },
//
//     //获取手机验证码方法
//     getCode() {
//       // 校验手机号码
//       if (!this.ruleForm.phone) {
//         //号码校验不通过
//         this.$message({
//           message: '请输入手机号',
//           type: 'warning',
//         });
//         //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
//       } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.ruleForm.phone)) {
//         // 失去焦点后自动触发校验手机号规则
//         this.$message({
//           message: '手机号格式不正确',
//           type: 'warning',
//         });
//       } else {
//         let params = {
//           "phone": this.ruleForm.phone,
//         }
//         registerOutPre(params).then(res => {
//           this.time = 60;
//           this.disabled = true;
//           //调用倒计时方法
//           this.timer();
//         })
//
//       }
//     },
//     timer() {
//       if (this.time > 0) {
//         this.time--;
//         // console.log(this.time);
//         this.btnText = this.time + "s后重新获取验证码";
//         setTimeout(this.timer, 1000);
//       } else {
//         this.time = 0;
//         this.btnText = "获取验证码";
//         this.disabled = false;
//       }
//     },
//     /** 10s  计时**/
//     goNext() {
//       this.dialogVisible = true
//       if (!this.canClick) return
//       this.canClick = false
//       this.sendShort = `${this.totalTime} s 确认`// 避免变成空
//       this.cloak = setInterval(() => {
//         this.totalTime--
//         if (this.totalTime > 0) {
//           this.sendShort = `${this.totalTime}s 确认`
//         }
//         if (this.totalTime <= 0) {
//           window.clearInterval(this.cloak)
//           this.sendShort = '确认'
//           this.totalTime = 10
//           this.canClick = true
//         }
//       }, 1000)
//     },
//     /** 10s  计时**/
//     getNum() {
//       if (this.canClick === true) {
//         this.$router.push('/register_jj')
//       } else {
//         this.$message.warning('请阅读注册协议')
//       }
//     }
//   }
// }
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/img/User/register.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
.register_result_content{
  width: 1174px;
  min-height: 446px;
  background: #FFFFFF;
  box-shadow: 0px 3px 36px 4px #CDD3EB;
  border-radius: 0px 40px 0px 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 20px;

  .title {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    line-height: 55px;
    text-align: center;
    margin-bottom: 117px;
  }
  .register_box {
    height: calc(100% - 55px);
  }
  .p1{
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
  }
  .p2{
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color:var(--el-color-primary);
  }
}


}
</style>
