<template>
  <div class='password  flex flex-center'>
    <div class='password_content'>
      <div class="title">找回密码</div>
      <div class='steps-box'>
        <el-steps :active='active' finish-status='success'>
          <el-step title=''>
            <template #icon>
              <div class="stepIcon" :class="active==0?'active':''">
                验证
              </div>
            </template>
          </el-step>
          <el-step title=''>
            <template #icon>
              <div class="stepIcon" :class="active==1?'active':''">
                重置
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div class='register-type'>
        <div class='form-box'>
          <div v-if="active==0">

            <OutlinedInput v-model:value="ruleForm.phone" placeholder="请输入手机号码" :inputHeight="42"
                           :typeInput="'text'"

            ></OutlinedInput>
            <div class="flex items-center ">
              <div class="" style="width:calc(100% - 130px )">
                <OutlinedInput v-model:value="ruleForm.code" placeholder="验证码" :inputHeight="42"
                               :typeInput="'text'"

                ></OutlinedInput>
              </div>
              <div class="sendSms cursor-pointer" v-ripple="'rgba(0, 0, 0, 0.15)'" @click="getCodeFun">{{
                  btnText
                }}
              </div>

            </div>
          </div>
          <div v-if="active==1">
            <OutlinedInput v-model:value="ruleForm.password" placeholder="新密码" :inputHeight="42"
                           :typeInput="'password'" :show-password="true"
                           @input="changePasswordInput"
            ></OutlinedInput>
            <div class="flex items-center" v-if="strength">
              <span class="flex-shrink-0">密码强度：</span>
              <div
                  class="bar"
                  :style="{ background: barColor, width: width + '%' }">
                <!-- 展示文字 -->
                <div
                    class="strength text-center"
                    :style="{ color:'#fff'}">
                  {{ strength }}
                </div>
              </div>
            </div>
            <OutlinedInput v-model:value="ruleForm.confirmPwd" placeholder="确认新密码" :inputHeight="42"
                           :typeInput="'password'" :show-password="true"

            ></OutlinedInput>
          </div>
          <!--            <el-button type='primary' @click='next'>返回</el-button>-->
          <div class="flex p-t-20">
            <p type="text" class="btn_p cursor-pointer mr-2" @click='goback' v-if="active==0">返回</p>
            <el-button type='primary' style="width: calc(100% - 80px);" class="btn" @click='next' v-if="active==0">
              下一步
            </el-button>
            <p type="text" class="btn_p cursor-pointer mr-2" @click='goback' v-if="active==1">返回</p>
            <el-button type='primary' style="width: calc(100% - 80px);" class="btn" @click='next' v-if="active==1">
              确认重置
            </el-button>
          </div>
          <!--          <div>-->
          <!--            &lt;!&ndash;            <el-button type='primary' @click='next'>返回</el-button>&ndash;&gt;-->
          <!--            <el-button type='primary' class="btn cursor-pointer" @click='next' v-if="active==0">下一步</el-button>-->
          <!--            <div class="flex">-->
          <!--              <p type="text" class="btn_p cursor-pointer" style="width: 100px" @click='goback'-->
          <!--                 v-if="active==1||active==2">返回</p>-->
          <!--              <el-button type='primary' style="width: calc(100% - 100px);" class="btn" @click='next'-->
          <!--                         v-if="active==1||active==2">{{ active == 1 ? '下一步' : '完成' }}-->
          <!--              </el-button>-->
          <!--            </div>-->
          <!--          </div>-->

        </div>
      </div>
    </div>
    <!--        <el-dialog-->
    <!--            v-model:value:visible='dialogVisible'-->
    <!--            title='平台相关协议'-->
    <!--            width='60%'-->
    <!--            center-->
    <!--            :show-close='false'-->
    <!--            :close-on-click-modal='false'-->
    <!--        >-->
    <!--            <div class='agreement'>-->
    <!--                <iframe-->
    <!--                    id='iframename'-->
    <!--                    src='https://cf-user.chengfengkuaiyun.com/cf-register.html'-->
    <!--                    width='100%'-->
    <!--                    height='800'-->
    <!--                    frameborder='0'-->
    <!--                    scrolling='auto'-->
    <!--                    name='iframename'-->
    <!--                />-->
    <!--            </div>-->
    <!--            <span slot='footer' class='dialog-footer'>-->
    <!--                <el-button type='danger' @click='closeMask'>取 消</el-button>-->
    <!--                <el-button type='success' @click='getNum'>{{ sendShort }}</el-button>-->
    <!--            </span>-->
    <!--        </el-dialog>-->
  </div>
</template>

<script>
import {changePassword, changePasswordFort, checkUsername, getCode, verifyMessageCode} from "@/api/UserInfo";
import {ElMessage} from 'element-plus'
import JSEncrypt from "jsencrypt";
import OutlinedInput from "@/components/OutlinedInput.vue";
import passwordStrength from "@/components/passwordStrength/index.vue";
import {ref} from "vue";
import {checkPasswordRule, level} from '@/components/passwordStrength/CheckPassword';

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk2nvbv1V92I7wodOVGhAezEHrRHsPj3XQRzLEUYzhkXSqTAVDJrC3fS8c8bz2NDIROr5XzOmgTVrDIFL/kYSjc79Q+65684pYMN2R+pZKplB8hlUhJ5LscrES43TquLM6Oycp98D64tqtGm/XIURoi4b9iDQpVFNFqk3O1K919wIDAQAB';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
export default {
  components: {OutlinedInput, passwordStrength},
  data() {
    return {
      strength: '',
      sendShort: '',
      barColor: '',
      width: '',
      totalTime: '10',
      canClick: true, // 控制是否可以点击
      dialogVisible: false,
      active: 0,
      btnText: "获取验证码",
      time: 60,
      ruleForm: {
        phone: '',
        code: '',
        confirmPwd: '',
        password: '',
      },
    }
  },
  created() {
  }
  ,
  methods: {
    changePasswordInput(row) {
      if (row) {
        if (row.target) {
          let v = row.target.value
          if (v != '') {
            const res = level(v);
            if (res === '非常弱') {
              this.barColor = 'red';
              this.width = 20;
              this.strength = '非常弱';
            } else if (res === '弱') {
              this.barColor = '#ee795c';
              this.width = 30;
              this.strength = '弱';
            } else if (res === '一般') {
              this.barColor = 'orange';
              this.width = 50;
              this.strength = '中';
            } else if (res === '强') {
              this.barColor = 'green';
              this.width = 70;
              this.strength = '强';
            } else if (res === '非常强') {
              this.barColor = '#1B8EF8';
              this.width = 80;
              this.strength = '非常强';
            }
          } else {
            this.barColor = '';
            this.width = 0;
            this.strength = '';
          }
        }
      }
    },
    next() {
      if (this.active === 0) {
        // 手机验证码验证
        if (!this.ruleForm.phone) {
          ElMessage.error("手机号不能为空")
          return
        }
        if (!this.ruleForm.code) {
          ElMessage.error("验证码不能为空")
          return
        }
        let params = {
          code: this.ruleForm.code,
          phone: this.ruleForm.phone
        }
        verifyMessageCode(params).then(res => {
          if (res.code === 200) {
            this.active += 1
            return
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
      if (this.active === 1) {
        if (!this.ruleForm.password) {
          ElMessage.error("密码不能为空")
          return
        }
        if (!this.ruleForm.confirmPwd) {
          ElMessage.error("确认密码不能为空")
          return
        }
        if (!this.strength || this.strength == '弱' || this.strength == '非常弱') {
          ElMessage.error("密码安全等级不足,请重新输入")
          return
        }
        if (this.ruleForm.confirmPwd !== this.ruleForm.password) {
          ElMessage.error("两次密码不一致，请重新输入")
          return
        }
        let params = {
          "confirmPwd": encrypt.encrypt(this.ruleForm.confirmPwd),
          "password": encrypt.encrypt(this.ruleForm.password),
          "phone": this.ruleForm.phone
        }
        changePasswordFort(params).then(res => {
          if (res.code === 200) {
            ElMessage.success("操作成功")
            this.$router.push("/login")

          }
        })
      }
    }
    ,
// 返回
    goback() {
      if (this.active == 0) {
        this.$router.go(-1)
      } else {
        this.active -= 1
      }
    }
    ,
    goLogin() {
      this.$router.push('/login')
    }
    ,
    closeMask() {
      this.dialogVisible = false
      // window.clearInterval(this.cloak);
      // this.totalTime='10';
    }
    ,

//获取手机验证码方法
    getCodeFun() {
      if (this.time !== 60) {
        return
      }
      // 校验手机号码
      if (!this.ruleForm.phone) {
        //号码校验不通过
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
        });
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.ruleForm.phone)) {
        // 失去焦点后自动触发校验手机号规则
        this.$message({
          message: '手机号格式不正确',
          type: 'warning',
        });
      } else {
        let params = {
          "phone": this.ruleForm.phone,
          "resetType": 0,
          "username": encrypt.encrypt(this.ruleForm.username)
        }
        getCode(params).then(res => {
          if (res.code === 200) {
            this.time = 60;
            this.disabled = true;
            //调用倒计时方法
            this.timer();
          }
        }).catch(err => {
          this.btnText = "获取验证码";
          this.time = 60;
          this.disabled = false;
        })

      }
    }
    ,
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btnText = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 60;
        this.btnText = "获取验证码";
        this.disabled = false;
      }
    }
    ,
  }
}
</script>

<style lang="less" scoped>
.password {
  height: 100vh;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/User/login01.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .password_content {
    width: 410px;
    height: 446px;
    background: #FFFFFF;
    box-shadow: 0px 3px 36px 4px #CDD3EB;
    border-radius: 0px 40px 0px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .title {
      text-align: center;
      position: relative;
      margin: 40px auto;
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 20px;
      color: #000000;

    }


    .steps-box {
      width: calc(100% - 110px);
      margin: 0 auto;

      .stepIcon {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #919191;
        color: #919191;
      }

      .active {
        border: 1px solid #3381FF;
        color: #3381FF;
      }

      ::v-deep(.el-step__line-inner) {
        display: none;
      }

      ::v-deep(.el-step__line) {
        height: 1px;
        position: absolute;
        border-color: inherit;
        background-color: transparent;
        border: 1px dashed var(--el-text-color-placeholder);
      }

      .el-step.is-simple:not(:last-of-type) .el-step__title {
        max-width: none;
      }
    }
  }

  .itemForm {
    height: 50px;
    box-sizing: border-box;
    margin: 0 auto 40px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;

    .el-input {
      width: 100%;
      height: 100%;
    }

    ::v-deep(.el-input__inner) {
      width: 100%;
      height: 100%;
      border: none !important;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #333333 !important;
    }

    ::v-deep(.el-input__wrapper) {
      box-shadow: none;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .code {
    position: relative;
  }

  .getCode {
    position: absolute;
    right: 30px;
  }

  .btn_p {
    width: 80px;
    position: relative;
    font-family: Source Han Sans CN;
    font-weight: 300;
    font-size: 14px;
    color: #333333;
  }

  .btn_p::after {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 30px;
    bottom: 0;
    background: #333333;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .btn {
    width: 100%;
    height: 44px;
    background: #1890FF;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 33px;
    margin: 0 auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }


  .prefixUserRight {
    width: 1px;
    height: 34px;
    background: #DCDCDC;
    margin-right: 16px;
  }

  .register-title {
    width: 1000px;
    height: 98px;
    background: #ffffff;
    line-height: 98px;
    margin: 0 auto;
    display: flex;

    .logo-item {
      width: 137px;
      height: 34px;
      //background: url("./images/zhucetitle.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 32px;
    }

    .world-item {
      width: 96px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      margin-top: 33px;
      margin-left: 20px;
      color: #05B37B;
      border-left: 2px solid #E9E9E9;
    }

    .login-item {
      margin-left: 615px;
      font-size: 14px;
      color: #555555;

      em {
        color: #05B37B;
        cursor: pointer;
      }
    }
  }

  .register-image {
    height: 182px;
    line-height: 182px;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    width: 100%;
    /*background: #00CB8A;*/
    //background: url('./images/navbar.png') no-repeat;
    background-size: 100% 100%;
  }

  .register-type {
    width: calc(100% - 110px);
    margin: 0 auto;
    margin-top: 20px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/

    img {
      display: block;
      width: 148px;
      height: 124px;
      /*border: 1px solid #33aef0;*/
      margin: 0 auto;
    }

    p {
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-top: 14px;
    }

  }

  .register-container {
    padding: 30px;
    width: 1000px;
    height: 510px;
    margin: 0 auto;


    .next-box {
      width: 400px;
      bottom: 20px;
      margin: 0 auto;

      .el-button {
        width: 100%;
        background: #00cb8a;
      }

      .el-button--primary {
        border-color: transparent;
      }
    }
  }

  .agreement {
    user-select: none;
    width: 100%;
    height: 500px;
    overflow: auto;
  }

  .sendSms {
    width: 117px;
    height: 42px;
    line-height: 42px;
    margin-left: 11px;
    background: #F2F2F2;
    text-align: center;
    border-radius: 1px 1px 1px 1px;
    border: 1px solid #DDDDDD;
  }
}
</style>
