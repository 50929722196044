<template>
  <div class="custom-input-container cascader" :class="isBorder?'boxsha': 'defaultBorder'">
    <el-cascader
        v-model="inputValue"
        :options="options"
        :props="propsValue"
        @change="handleChange"
        :placeholder="placeholder"
        :show-all-levels="showAllLevels"
        :style="{height: inputHeight+'px',width:'100%'}"
        @focus="handleFocus"
        @blur="handleBlur"
        @visible-change="handleVisibleChange"
    />
    <div :class="{
             'input-focused': focused || inputValue.length,
          'input-tip': !focused && !inputValue.length,
          'noColorPrimary': inputValue.length && !focused
        }">{{ placeholder }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Array,
      default: []
    },
    options: Array,
    showAllLevels: Boolean,
    propsValue: Object,
    placeholder: String,
    typeInput: String,
    showPassword: Boolean,
    inputHeight: Number,
    isBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.value,
      focused: false,
      bulr: false,
      visible: false,
    };
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  watch: {
    inputValue(newValue) {
      // 当 inputValue 发生变化时，触发 update 事件，并传递新值
      this.$emit('update:value', newValue);
    },
    value: {
      handler(newVal) {
        this.inputValue = newVal;
      },
      immediate: true
    },
  },
  methods: {
    handleVisibleChange(val) {
      this.visible = val
      if (!val && this.bulr) {
        this.focused = false
      }

    },
    handleFocus() {
      this.bulr = false
      this.focused = true;
    },
    handleChange() {
      this.focused = true;
    },
    handleBlur(val) {
      this.bulr = true
      if (!this.visible) {
        this.focused = false;
        this.$emit('update:value', this.inputValue);
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.custom-input-container {
  position: relative;
  margin-bottom: 20px; /* 调整与下方元素的间距 */
  padding-top: 20px;
}

::v-deep(.el-cascader .el-input) {
  height: 100% !important;
}

.input-focused {
  transform: translate(14px, -6px) scale(1);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  position: absolute;
  top: 18px; /* 提示信息与输入框的距离 */
  left: -2px; /* 提示信息与输入框左边的距离 */
  padding: 2px 5px;
  background-color: #fff; /* 背景颜色 */
  border-radius: 2px;
  font-size: 12px;
  line-height: 9px;
  color: var(--el-color-primary);
  z-index: 1; /* 确保在输入框上方显示 */
  opacity: 1; /* 默认显示 */
}

.input-tip {
  position: absolute;
  top: 50%; /* 提示信息与输入框的距离 */
  left: 1px; /* 提示信息与输入框左边的距离 */
  opacity: 0; /* 默认显示 */
  transform: translate(14px, 6px) scale(1);
  z-index: -1;
  color: #9f9f9f;
}

.noColorPrimary {
  color: #9f9f9f;
}

</style>
<style>
/* .el-input__wrapper:hover{
  box-shadow: none ;
} */
.boxsha .el-input__wrapper {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
</style>
