<template>
  <div>

    <OutlinedInput v-model:value="ruleForm.phone" :disabled="isabled" placeholder="请输入手机号码" :inputHeight="42"
                   :typeInput="'text'"

    ></OutlinedInput>
    <div class="flex items-center ">
      <div class="" style="width:calc(100% - 130px )">
        <OutlinedInput v-model:value="ruleForm.smsCode" placeholder="验证码" :inputHeight="42"
                       :typeInput="'text'"

        ></OutlinedInput>
      </div>
      <div class="sendSms cursor-pointer" v-ripple="'rgba(0, 0, 0, 0.15)'" @click="getCodeFun">{{
          btnText
        }}
      </div>

    </div>
    <div class="form-reset-submit flex justify-end">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button style="margin-right: 20px" @click="reset">关闭</el-button>
    </div>
  </div>
</template>
<script>
import {checkPassword} from '@/utils/common'
// import { resetPassword } from '@/api/UserInfo'
import {authPhone, changePassword, getAuthSmsCode} from "@/api/UserInfo";
import JSEncrypt from "jsencrypt";
import actions from "@/action";
import OutlinedInput from "@/components/OutlinedInput.vue";

export default {
  props: {
    ischange: {
      type: Boolean,
      default: false
    },
    isabled: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      default: ''
    },
  },
  data() { // 初始化数据
    //18888888888@8888!
    return {
      ruleForm: {
        phone: '',
        smsCode: '',
      },
      btnText: "获取验证码",
      time: 60,
    }
  },
  methods: {
    //init
    init(val) {
      this.ruleForm.phone = val
    },
    // 定义函数
    //获取手机验证码方法
    getCodeFun() {
      if (this.time !== 60) {
        return
      }
      // 校验手机号码
      if (!this.ruleForm.phone) {
        //号码校验不通过
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
        });
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.ruleForm.phone)) {
        // 失去焦点后自动触发校验手机号规则
        this.$message({
          message: '手机号格式不正确',
          type: 'warning',
        });
      } else {
        let params = {
          "phone": this.ruleForm.phone,
        }
        getAuthSmsCode(params).then(res => {
          if (res.code === 200) {
            this.time = 60;
            this.disabled = true;
            //调用倒计时方法
            this.timer();
          }
        }).catch(err => {
          this.btnText = "获取验证码";
          this.time = 60;
          this.disabled = false;
        })

      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btnText = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 60;
        this.btnText = "获取验证码";
        this.disabled = false;
      }
    },
    reset() {
      this.ruleForm.phone = ''
      this.ruleForm.smsCode = ''
      this.$emit("closePassword")

    },
    onSubmit() {
      if (!this.ruleForm.phone) {
        //号码校验不通过
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
        });
        return
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      }
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.ruleForm.phone)) {
        // 失去焦点后自动触发校验手机号规则
        this.$message({
          message: '手机号格式不正确',
          type: 'warning',
        });
        return
      }
      if (!this.ruleForm.smsCode) {
        // 失去焦点后自动触发校验手机号规则
        this.$message({
          message: '验证码不能为空',
          type: 'warning',
        });
        return
      }

      authPhone({
        phone: this.ruleForm.phone,
        smsCode: this.ruleForm.smsCode,
      }).then(res => {
        if (res.code === 200) {
          if (this.ischange) {
            this.$emit("submitPassword", this.ruleForm.phone)
          } else {
            this.$message({
              type: 'success',
              message: '手机号认证成功！',
              duration: 1000,
            })
            this.$emit("closePassword")
          }
          this.reset()
        } else {
          this.$message({type: 'error', message: '认证失败'})
        }
      }, error => {
        // this.$message({ type: 'error', message: '修改密码失败' })
      })
    }
  },
  components: {
    OutlinedInput // 解构映射到组件

  }
}
/*
data() { // 初始化数据
  return {}
},
methods: { // 定义函数

},
components: { // 解构映射到组件

}
beforeCreate () { // 创建前状态

},
created () { // 创建完毕状态

},
beforeMount () { // 初始化完成前状态

},
mounted() { //初始化完成后的回调函数

},
beforeUpdate() { // 更新前状态

},
updated() { // 更新完成状态

},
beforeDestroy() { // 销毁前状态

},
destroyed() { // 销毁完成状态

},
computed: { // 计算属性

},
watch: { // 深度监视，数据一旦发生改变，立马更新保存数据

},
*/
</script>
<style scoped lang='less'>

.form-reset-submit {
  margin-top: 20px;
}

.sendSms {
  width: 117px;
  height: 42px;
  line-height: 42px;
  margin-left: 11px;
  background: #F2F2F2;
  text-align: center;
  border-radius: 1px 1px 1px 1px;
  border: 1px solid #DDDDDD;
}
</style>
